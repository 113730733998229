import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { DropdownOptions } from '../../generic/user-dropdown/user-dropdown.component';
import { Router, NavigationEnd } from '@angular/router';
import {RestProvider} from 'src/app/providers/rest/rest';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent implements OnInit, OnDestroy {

  @Input() brand: BrandOptions = { name: '', logoUrl: '' };
  @Input() subtitle: string;
  @Input() routeBase = '/';
  @Input() menu: DropdownOptions;

  canGoBack = false;
  permissionName: string;
  public $isLoading: Subscription;
  public isLoading: boolean;

  constructor(
    private rest: RestProvider,
    public router: Router,
    private navCtrl: NavController) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.canGoBack = val.urlAfterRedirects != this.routeBase;
      }
    });

  }

  ngOnInit() {
    // Subscribe to the global isLoading Obserable as a subscription
    this.$isLoading = this.rest.getIsLoading()
      .subscribe (
        loading => {
          this.isLoading = loading;
      });

  }

  ngOnDestroy() {
    // Destroy/Unsubscribe the global isLoading Obserable as a subscription
    if (this.$isLoading) { this.$isLoading.unsubscribe(); }
  }


  goBack() {
    if (this.canGoBack) {
      this.navCtrl.back();
    }
  }

}

export interface BrandOptions {
  logoUrl: string;
  name: string;
}
