import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ProfileHeaderComponent} from './custom/profile-header/profile-header.component';
import {UserDropdownComponent} from './generic/user-dropdown/user-dropdown.component';
import {IconInputComponent} from './generic/icon-input/icon-input.component';
import {SignatureSectionComponent} from './custom/signature-section/signature-section.component';
import { SignaturePadModule } from './custom/signature-pad/signature-pad.module';
import {LoginHeaderComponent} from './custom/login-header/login-header.component';
import {CameraCaptureComponent} from './custom/camera-capture/camera-capture.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { DefectRepairComponent } from './custom/defect-repair/defect-repair.component';
import { LiveSearchSelectableComponent } from './generic/live-search-selectable/live-search-selectable.component';


@NgModule({
  declarations: [
    IconInputComponent,
    SignatureSectionComponent,
    LoginHeaderComponent,
    ProfileHeaderComponent,
    UserDropdownComponent,
    CameraCaptureComponent,
    DefectRepairComponent,
    LiveSearchSelectableComponent
  ],
  exports: [
    IconInputComponent,
    SignatureSectionComponent,
    LoginHeaderComponent,
    ProfileHeaderComponent,
    UserDropdownComponent,
    CameraCaptureComponent,
    DefectRepairComponent,
    LiveSearchSelectableComponent
  ],
  imports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SignaturePadModule,
    IonicSelectableModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ComponentsModule {
}
