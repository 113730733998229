import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/providers/global/global';
import { Defect, DefectRepairer, DefectStatus, IDefectStatus } from 'src/app/providers/models/user-cust-models';
import { RestProvider } from 'src/app/providers/rest/rest';

@Component({
  selector: 'app-modal-vehicle-defects',
  templateUrl: './modal-vehicle-defects.component.html',
  styleUrls: ['./modal-vehicle-defects.component.scss'],
})
export class ModalVehicleDefectsComponent implements OnInit {
  isLoading = false;
  searchMessage: string = '';
  defects: Defect[] = []; // TODO - Needs type/interfacing
  defectStatusObject = DefectStatus;
  top_three: Defect[] = [];

  searchOptions: { selected: boolean, defect: IDefectStatus }[] = [{
    selected: false,
    defect: {
      color: '',
      icon: '',
      id: 0,
      name: 'All'
    }
  }];

  // Data passed in by componentProps
  @Input() registration: string;
  @Input() repairer: DefectRepairer;
  @Input() is_top_three: boolean;
  @Input() currentDefect: Defect | null = null;


  constructor(
    private modalCtrl: ModalController,
    private global: GlobalVariable,
    private rest: RestProvider,
    private navCtrl: NavController,
  ) { }
  name: string;

  ngOnInit() {
    console.log('ModalVehicleDefectsComponent ngOnInit');

    // Loop through all defect status' and populate the select box.
    Object.values(DefectStatus).forEach(status => {
      if (status !== DefectStatus.Complete) {
        // if (this.permissionService.hasRolePermission('is_repairer') && (status.id === 357913941 || status.id === 715827882)) {
        //   return;
        // }

        this.searchOptions.push({
          defect: status,
          selected: true
        });
      }
    });

    // Get the defects from API
    this.getDefects();
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }

  private getDefects() {
    // console.log('getting Defects...', this.depots);
    this.isLoading = true;
    const wantedSearches = [];
    this.searchOptions.forEach(element => {
      if (element.selected && element.defect.id !== 0) {
        wantedSearches.push(element.defect.id);
      }
    });
    console.log('Wanted Searchs', wantedSearches);

    // const depots = this.depotFilter.value !== 'none' ? [this.depotFilter.value] : this.depots.map((x) => x = x.value).slice(1, this.depots.length);

    const defects = this.rest.GetDefects(
      null,
      wantedSearches,
      null,
      null,
      this.registration,
      null,
      null,
      null
    ).then((res: any) => {

      let defect_results = res.results;
      
      if (this.is_top_three) {
       
        if (defect_results.length >= 3) {
          this.top_three = defect_results.slice(0, 3);
          
          defect_results = defect_results.slice(3);
          
          
          this.defects = this.filterOutRepaired(defect_results);
        } else {
          this.top_three = defect_results;
        }
        console.log('Fetched', res);
      } else {
        this.defects = this.filterOutRepaired(defect_results)
      }
      this.isLoading = false;
      if (this.defects.length === 0) {
        this.searchMessage = 'No defects found';
      }
    })
      .finally(() => {
        // if (this.refresher) {
        //   this.refresher.complete();
        // }
      });
  }

  private filterOutRepaired(defects) {
    const filtered = defects.filter(defects => {
      return defects.status !== 1521134249
    })
    return filtered
  }

  public click_view(defect) {
    const uuid = defect.uuid;
    this.modalCtrl.dismiss(this.name, 'confirm');
    this.navCtrl.navigateForward(`/dashboard/defect/view/${uuid}`);
  }

}
