import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../providers/auth/auth.service';
import { GlobalVariable } from '../providers/global/global';
import { DataProvider } from '../providers/data/data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private global: GlobalVariable,
    private router: Router,
    private dataProvider: DataProvider) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.checkToken().then((value) => {

      if (this.authService.authenticationState.getValue()) {
        if (!AuthService.accessToken && this.authService.token) {
          AuthService.accessToken = this.authService.token;
          this.authService.user = this.authService.getUserProfile();

          this.dataProvider.getCustomer().then(customer => {
            this.global.customer = customer;
          });
        }

        const hasAccessToApp = !!this.authService.user.extra_vars.permissions.find((x) => x.ExternalAppReference === environment.appReference);
        if (!hasAccessToApp) {
          this.router.navigateByUrl(`/restricted`);
        }
        return hasAccessToApp;
      } else {
        this.router.navigateByUrl(`/login?returnUrl=${state.url}`);
        return false;
      }
    }).catch((error) => {
      return false;
    });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
