import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActionSheetController, NavController, IonInfiniteScroll, IonRefresher, PopoverController, AlertController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/providers/global/global';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { PermissionsProvider } from '../../../providers/permissions/permissions';
import { RestProvider } from 'src/app/providers/rest/rest';
import { DataProvider } from 'src/app/providers/data/data';
import { Defect, DefectStatus, IDefectNote, IDefectStatus, LogoutType } from '../../../providers/models/user-cust-models';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import { ActionSheetOptions } from '@ionic/core';
import { Subject, Subscription, interval } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit, OnDestroy {

  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher, { static: false }) refresher: IonRefresher;
  defects: any = []; // TODO - Needs type/interfacing

  refreshInterval = interval(60 * 1000 * 2);
  $refreshSub: Subscription;

  repairers = [];
  isLoadingRepairers = false;
  loadingSearch: boolean;
  searchInput: string;
  defectsLastResultTime = 0;
  defectsHasNextPage = '';

  searchValid: boolean = null;

  filterSelected = null;
  filterSelectOptions = [{
    label: 'All Defects',
    value: ''
  }];

  requireLogin = false;
  reauth = {
    username: '',
    email: '',
    password: '',
    autologin: true
  };
  reauthName = '';
  loadingReauth = false;
  searchMessage: string;

  addingDefect = false;
  depots = [];
  repairFilter: any = {};
  depotFilter: any = {};
  regFilter = '';
  driverFilter = '';
  toogleFilterContainer = false;
  defectNumberFilter = '';
  defectNumberFullFilter = '';
  filterByVehicleTypeSelected = false;
  defectStatusObject = DefectStatus;
  private newSearchCriteria: Subject<void> = new Subject();
  private $newSearchCriteriaSubscription: Subscription;

  searchOptions: { selected: boolean, defect: IDefectStatus }[] = [{
    selected: false,
    defect: {
      color: '',
      icon: '',
      id: 0,
      name: 'All'
    }
  }];

  authConsentDriver = false;
  authConsentManager = false;

  constructor(
    public navCtrl: NavController,
    public authService: AuthService,
    public permissionService: PermissionsProvider,
    private global: GlobalVariable,
    private rest: RestProvider,
    private data: DataProvider,
    public actionSheetController: ActionSheetController,
    private alertController: AlertController) {
    this.loadingSearch = false;
    // Loop through all defect status' and populate the select box.
    Object.values(DefectStatus).forEach(status => {
      if (status !== DefectStatus.Complete) {
        if (this.permissionService.hasRolePermission('is_repairer') && (status.id === 357913941 || status.id === 715827882)) {
          return;
        }

        this.searchOptions.push({
          defect: status,
          selected: status.name !== 'Repaired'
        });
      }
    });
  }

  Reauthenticate() {
    this.reauth.username = this.authService.getUserProfile().extra_vars.email;

    this.global.toast('Dashboard Unlocked.');
    this.data.requireReauth(false);
    this.requireLogin = false;
    this.loadingReauth = false;
    this.reauth.password = '';
    this.authConsentManager = false;
    this.authConsentDriver = false;
  }

  public confirmAuth(value: boolean, type: 'driver' | 'manager') {
    if (type === 'driver') {
      this.authConsentDriver = value;
    }
    else if (type === 'manager') {
      this.authConsentManager = value;
    }
  }

  ngOnInit() {
    this.setupRefreshTimer();
    this.setupFilterListener();
  }

  ionViewDidEnter() {
    this.setupRefreshTimer();
    this.setupFilterListener();
  }

  setupFilterListener() {
    if (!this.$newSearchCriteriaSubscription || this.$newSearchCriteriaSubscription.closed) {
      this.$newSearchCriteriaSubscription = this.newSearchCriteria.pipe(
        debounceTime(500)
      ).subscribe((res) => {
        if (this.depots.length > 1) {
          this.getDefects();
        } else if (this.defectsLastResultTime != 0) {
          this.global.toast('There are no depots!');
        }
        // console.log('Debounced.');
      });
    }
  }

  setupRefreshTimer() {
    if (!this.$refreshSub) {
      this.$refreshSub = this.refreshInterval.subscribe(res => {
        console.log('-------------------------> Auto refreshing defects');
        this.getDefects();
      });
    }
  }

  ionViewDidLeave() {
    this.cleanup();
  }

  ngOnDestroy() {
    this.cleanup();
  }

  cleanup() {
    if (this.$newSearchCriteriaSubscription && !this.$newSearchCriteriaSubscription.closed) {
      this.$newSearchCriteriaSubscription.unsubscribe();
    }

    if (this.$refreshSub && !this.$refreshSub.closed) {
      this.$refreshSub.unsubscribe();
    }
  }


  toggleFilter() {
    this.toogleFilterContainer = !this.toogleFilterContainer;
  }
  select_FilterChange() {
    // console.log('Filter Search: ' + this.filterSelected);
  }

  select_NumberFilterChange(numberFilter: string) {
    if (this.defectNumberFullFilter !== numberFilter) {
      this.defectNumberFullFilter = numberFilter;
      this.newSearchCriteria.next();
    }
  }

  select_RepairFilterChange($event) {
    // console.log('Filter Change:', $event);
    // this.getDefects();
    this.newSearchCriteria.next();
  }

  async loadRepairers(locations: any[]) {
    let repairersLoaded = 0;
    this.isLoadingRepairers = true;
    let allRepairers = [];

    const repairPromises = [];

    for (const depot of locations) {
      // repairPromises.push(this.rest.GetRepairers(depot.id));

      repairPromises.push(this.rest.GetRepairers(depot.id).then((repairers) => {
        const newLoad = { label: `Loading ${++repairersLoaded}/${locations.length}...`, value: null };
        this.repairFilter = newLoad;
        allRepairers = allRepairers.concat(repairers);
      }));
    }

    Promise.all(repairPromises).then(() => {
      console.log('End Result', allRepairers);
      this.isLoadingRepairers = false;

      this.repairers = allRepairers.map((x) => x = {
        label: x.name,
        value: x.name
      });

      const noRepairer = {
        label: 'All',
        value: null
      };

      this.repairFilter = noRepairer;
      this.repairers.unshift(noRepairer);
    })
  }

  loadDepots(locations: any[]) {
    let depotFiltered = locations.filter((x) => x.is_depot);
    depotFiltered.sort((a: any, b: any) => a.name > b.name ? 1 : -1);
    depotFiltered = depotFiltered.map((x: any) => x = {
      label: x.name,
      value: x.id
    });
    this.depots = depotFiltered;
    const none = {
      label: 'All',
      value: 'none'
    };
    this.depots.unshift(none);
    this.depotFilter = none;
  }

  async ionViewWillEnter() {
    this.data.requireReauth().then((result) => {
      this.requireLogin = result;
    });


    if (!this.data.hasGotInitialData) {
      this.data.hasGotInitialData = true;

      const loadingPlaceholder = { label: 'Initilising...', value: null };
      this.repairers = [loadingPlaceholder];
      this.repairFilter = loadingPlaceholder;
      this.depots = [loadingPlaceholder];
      this.depotFilter = loadingPlaceholder;

      const locations = await this.rest.DepotLocations() as any[];
      const newLoad = { label: `Loading 0/${locations.length}...`, value: null };
      this.repairers = [newLoad];
      this.repairFilter = newLoad;
      this.loadRepairers(locations);
      this.loadDepots(locations);
    }
    this.getDefects();

  }

  toggleDefect(defect: IDefectStatus) {
    if (defect.id === 0) {
      // If 'All' is toggled the remove all other selections.
      this.searchOptions.forEach(element => {
        element.selected = false;
      });
    } else {
      // Otherwise toggle the option that we pressed, and also set 'All' to false.
      this.searchOptions[0].selected = false;
      const option = this.searchOptions.find((x) => x.defect === defect);
      option.selected = !option.selected;
    }

    // We will toggle the All Defects sleection based on if any other selections are made.
    // if nothing is slected then we will receive all defects.
    let anySelected = false;
    this.searchOptions.forEach(element => {
      if (element.selected) {
        anySelected = true;
      }
    });
    this.searchOptions[0].selected = !anySelected;
    // this.getDefects();
    this.newSearchCriteria.next();
  }

  doRefresh($event) {
    this.getDefects();
  }

  getDefects() {
    console.log('getting Defects...', this.depots);
    const wantedSearches = [];
    this.searchOptions.forEach(element => {
      if (element.selected && element.defect.id !== 0) {
        wantedSearches.push(element.defect.id);
      }
    });
    console.log('Wanted Searchs', wantedSearches);

    const depots = this.depotFilter.value !== 'none' ? [this.depotFilter.value] : this.depots.map((x) => x = x.value).slice(1, this.depots.length);

    this.rest.GetDefects(
      depots,
      wantedSearches,
      this.repairFilter.value,
      this.driverFilter,
      this.regFilter,
      this.filterByVehicleTypeSelected,
      this.defectNumberFilter,
      this.defectNumberFullFilter
    ).then((res: any) => {
      if (res.timestamp > this.defectsLastResultTime) {
        this.defectsLastResultTime = res.timestamp;
      } else {
        return;
      }

      this.defectsHasNextPage = res.next;
      this.infiniteScroll.disabled = res.next == null;
      this.global.defectsInLastSearch = res.results;
      this.defects = res.results;
      console.log('Fetched', res);

      if (res.results.length === 0) {
        this.searchMessage = 'No defects found';
      }
    })
      .finally(() => {
        if (this.refresher) {
          this.refresher.complete();
        }
      });
  }

  filterByVehicleType() {
    this.filterByVehicleTypeSelected = !this.filterByVehicleTypeSelected;
    this.newSearchCriteria.next();
  }

  segmentChanged(ev: any) {
  }

  click_signOff() {
    // console.log('ssdsdd');
  }

  click_More(defect: Defect) {
    this.ShowMore(defect);
  }

  async ShowMore(defect: Defect) {
    // console.log('Clicked: ', defect);
    const uuid = defect.uuid;

    const actionSheetOptions: ActionSheetOptions = {
      header: 'Actions',
      buttons: [
        {
          text: 'View Defect',
          handler: () => {
            this.ViewDefect(uuid);
          }
        },
        {
          text: 'Close Menu',
          role: 'cancel',
          cssClass: 'action-cancel',
          handler: () => { }
        }
      ]
    };

    const status: IDefectStatus = this.global.DefectDetails(defect.status);
    if (!this.permissionService.hasRolePermission('is_readonly')) {
      if (status !== DefectStatus.Repaired) {
        actionSheetOptions.buttons.splice(1, 0, {
          text: 'View Notes',
          handler: () => {
            this.ViewDefectNotes(uuid);
          }
        });
      }
      if (status.next_action && !(status === DefectStatus.Signed_Off_Roadworthy && this.permissionService.hasRolePermission('is_repairer'))) {
        actionSheetOptions.buttons.unshift({
          text: status.next_action,
          handler: () => {
            this.UpdateDefect(defect.uuid);
          },
          cssClass: 'action-important'
        });
      }

      console.log(status.id, DefectStatus.At_Repairer.id, DefectStatus.Complete.id)

      if (status.id == DefectStatus.At_Repairer.id && (this.permissionService.hasRolePermission('is_admin') || this.permissionService.hasRolePermission('is_manager'))) {
        actionSheetOptions.buttons.splice(1, 0, {
          text: 'Transfer Repairer',
          handler: () => {
            this.openTransferSheet(defect);
          }
        });
      }
    }

    const actionSheet = await this.actionSheetController.create(actionSheetOptions);

    await actionSheet.present();
  }

  openTransferSheet(defect: Defect) {
    this.rest.setIsLoading(true);
    this.rest.GetRepairers(defect.location.external_id).then((res: any[]) => {
      const actionSheetOptions: ActionSheetOptions = {
        header: 'Transfer Repairer',
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'action-cancel',
          handler: () => { }
        }]
      };

      console.log('Repairers', res, defect);

      res.forEach((repairer: any) => {
        console.log()
        if (repairer.id != defect.repairer.id) {
          actionSheetOptions.buttons.unshift({
            text: repairer.name,
            handler: () => {
              this.confirmTransferDepot(defect, repairer)
            }
          });
        }

      });

      this.rest.setIsLoading(false);
      this.actionSheetController.create(actionSheetOptions).then((a) => a.present());
    });
  }

  private confirmTransferDepot(defect, repairer) {
    this.alertController.create({
      header: 'Repairer Transfer',
      message: 'Are you sure you want to transfer defect "' + defect.location_ref_number + "-" + defect.defect_number + '" to "' + repairer.name + '" repairer?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('No');
          }
        },
        {
          text: 'Yes',
          handler: async () => {
            this.transferDefectToAnotherDepot(defect, repairer)
          }
        }
      ],
      backdropDismiss: false
    }).then(alert => alert.present());

  }
  private transferDefectToAnotherDepot(defect, repairer) {
    this.rest.setIsLoading(true);
    // Set the defect in the homepage list with the new repairer
    defect.repairer = {
      name: repairer.name,
      external_id: repairer.id
    };
    const defectChange = {
      uuid: defect.uuid,
      status: defect.status,
      repairer: {
        name: repairer.name,
        external_id: repairer.id
      }
    }
    this.rest.DefectUpdate(defectChange).then(() => {
      // Make the defect list reload
      this.newSearchCriteria.next();
      this.rest.setIsLoading(false);
      const defectAction: IDefectNote = {
        defect: defectChange.uuid,
        comment: `Transferred defect to ${repairer.name}`,
        user_generated: false,
        files: []
      }
      this.rest.DefectNotePost(defectAction).then((result: IDefectNote) => {

      }).catch((error) => {
        this.displayApiError(error);
      });

    }).catch((error) => {
      this.displayApiError(error);
    });;
  }

  private displayApiError(errorResponse) {
    let message = '';
    switch (errorResponse.status) {
      case 400:
        message = errorResponse.error[0];
        break;
      case 500:
        message = 'An error has occurred';
        break;
      default:
        message = 'There was an issue submitting the defect';
        break;
    }
    this.global.toast(message);
  }

  click_AddDefect() {
    this.AddNewDefect();
  }
  click_ViewDefect(uuid: string) {
    this.ViewDefect(uuid);
  }
  click_UpdateDefect(uuid: string) {
    this.UpdateDefect(uuid);
  }

  AddNewDefect() {
    this.addingDefect = true;
    const defect: Defect = { uuid: uuid() };
    this.rest.DefectCreate(defect).then(result => {
      const returnedDefect = result as Defect;
      this.addingDefect = false;
      this.navCtrl.navigateForward(`/dashboard/defect/add/${returnedDefect.uuid}`);
    }).catch((error) => {
      this.global.toast('Failed to add new defect...');
      this.addingDefect = false;
    });
  }

  ViewDefect(uuid: string) {
    this.navCtrl.navigateForward(`/dashboard/defect/view/${uuid}`);
  }

  ViewDefectNotes(uuid: string) {
    this.navCtrl.navigateForward(`/dashboard/defect/notes/${uuid}`);
  }

  UpdateDefect(uuid: string) {
    this.navCtrl.navigateForward(`/dashboard/defect/update/${uuid}`);
  }

  loadData() {
    console.log('LOADING MORE');
    this.rest.GetNextDefectsPage(this.defectsHasNextPage).then((res: any) => {
      const defects = this.defects.concat(res.results);
      this.defectsHasNextPage = res.next;
      this.infiniteScroll.disabled = res.next === '';
      this.global.defectsInLastSearch = defects;
      this.defects = defects;
    })
      .finally(() => {
        this.infiniteScroll.complete();
      });
  }


}
