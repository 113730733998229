import * as tslib_1 from "tslib";
import { AlertController } from '@ionic/angular';
import * as i0 from "@angular/core";
var AlertServiceService = /** @class */ (function () {
    function AlertServiceService() {
        this.alertCtrl = new AlertController();
    }
    AlertServiceService.prototype.show = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, alert, clicked;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = false;
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: input.heading,
                                message: input.messageText,
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        role: 'cancel',
                                        handler: function () {
                                            result = false;
                                        }
                                    },
                                    {
                                        text: 'Ok',
                                        role: 'true',
                                        handler: function () {
                                            result = true;
                                        }
                                    }
                                ],
                                backdropDismiss: false
                            })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, alert.onDidDismiss()];
                    case 3:
                        clicked = _a.sent();
                        return [2 /*return*/, this.newState = result];
                }
            });
        });
    };
    AlertServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertServiceService_Factory() { return new AlertServiceService(); }, token: AlertServiceService, providedIn: "root" });
    return AlertServiceService;
}());
export { AlertServiceService };
