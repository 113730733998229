import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { AlertController } from '@ionic/angular';
import { RestProvider } from 'src/app/providers/rest/rest';
import { DataProvider } from 'src/app/providers/data/data';
var IconInputSettings = /** @class */ (function () {
    function IconInputSettings() {
        this.placeholder = '';
        this.type = 'text';
        this.icon = '';
        this.styles = '';
        this.enabled = true;
        this.readonly = false;
        this.verify = null;
        this.loading = false;
        this.value = '';
        this.action = null;
        this.options = [];
        this.selectFailAdd = true;
        this.selectFirst = true;
        this.pattern = null;
    }
    return IconInputSettings;
}());
export { IconInputSettings };
var IconInputComponent = /** @class */ (function () {
    function IconInputComponent(rest, alert, dataProvider) {
        this.rest = rest;
        this.alert = alert;
        this.dataProvider = dataProvider;
        this.settings = new IconInputSettings();
        this.action = new EventEmitter();
        this.customCSS = '';
        this.valueChange = new EventEmitter();
        this.manualAdd = new EventEmitter();
        this.date = new Date();
        this.min = this.date.getFullYear() - 50;
        this.max = this.date.getFullYear() + 50;
        this.alertCtrl = new AlertController();
        this.selectOptions = {
            header: this.settings.placeholder,
            cssClass: this.customCSS
        };
    }
    Object.defineProperty(IconInputComponent.prototype, "placeholder", {
        set: function (value) {
            this.settings.placeholder = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "type", {
        set: function (value) {
            this.settings.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "icon", {
        set: function (value) {
            this.settings.icon = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "autocomplete", {
        set: function (value) {
            this.settings.autocomplete = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "styles", {
        set: function (value) {
            this.settings.styles = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "enabled", {
        set: function (value) {
            this.settings.enabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "readonly", {
        set: function (value) {
            this.settings.readonly = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "pattern", {
        set: function (value) {
            this.settings.pattern = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "title", {
        set: function (value) {
            this.settings.title = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "inputPattern", {
        set: function (value) {
            this.settings.inputPattern = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "selectFirst", {
        set: function (value) {
            this.settings.selectFirst = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "selectFailAdd", {
        set: function (value) {
            this.settings.selectFailAdd = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "verify", {
        set: function (value) {
            this.settings.verify = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "loading", {
        set: function (value) {
            this.settings.loading = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "options", {
        set: function (value) {
            this.settings.options = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconInputComponent.prototype, "value", {
        set: function (value) {
            this.settings.value = value;
        },
        enumerable: true,
        configurable: true
    });
    IconInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.settings.type === 'date') {
            this.settings.icon = 'calendar';
            if (this.settings.value === 'today') {
                this.settings.value = new Date().toISOString();
            }
        }
        else if (this.settings.type === 'select' && this.settings.selectFirst) {
            if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
                this.settings.value = this.settings.options[0].value;
            }
        }
        else if (this.settings.type === 'search-select' && this.settings.selectFirst) {
            if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
                this.settings.value = this.settings.options[0];
            }
        }
        else if (this.settings.type === 'search-select-driver' && this.settings.selectFirst) {
            if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
                this.settings.value = this.settings.options[0];
            }
        }
        setTimeout(function () {
            _this.inputChange();
            if (_this.inputArea && _this.inputArea.nativeElement) {
                _this.inputArea.nativeElement.addEventListener('keyup', function (event) {
                    if (event.which === 13) { // If the input is active and 'Enter' has been pressed.
                        _this.action.emit();
                    }
                });
            }
        }, 0);
    };
    IconInputComponent.prototype.ionViewDidEnter = function () {
    };
    IconInputComponent.prototype.inputChange = function () {
        var _this = this;
        if (this.settings.inputPattern && !this.inputArea.nativeElement.checkValidity()) {
            this.inputArea.nativeElement.reportValidity();
            this.valueChange.emit('');
            return;
        }
        if (this.settings.type === 'date' && this.settings.value) {
            this.valueChange.emit(this.settings.value.split('T')[0]);
        }
        else if (this.settings.type === 'search-select') {
            this.valueChange.emit(this.settings.value);
        }
        else if (this.settings.type === 'search-select-driver') {
            this.valueChange.emit(this.settings.value);
        }
        else {
            this.valueChange.emit(this.settings.value);
        }
        if (this.settings.pattern && this.settings.value) {
            setTimeout(function () {
                var regex = new RegExp(_this.settings.pattern);
                var match = (regex.exec(_this.settings.value) || [''])[0];
                _this.settings.value = match;
                _this.valueChange.emit(_this.settings.value);
            });
        }
    };
    IconInputComponent.prototype.select_SelectableAction = function (event) {
        // Fix for Ionic Selectable search footer blocking select input.
        if (event.isSelected && this.selectSearch.searchText.length > 1) {
            this.settings.value = event.item;
            this.selectSearch.confirm();
            this.selectSearch.close();
        }
    };
    IconInputComponent.prototype.select_SelectableActionDriver = function (event) {
        // Fix for Ionic Selectable search footer blocking select input.
        if (event.isSelected && this.selectSearch.searchText.length > 1) {
            this.settings.value = event.item;
            this.selectSearch.confirm();
            this.selectSearch.close();
        }
    };
    IconInputComponent.prototype.click_Root = function () {
        if (this.settings.type === 'button' || this.settings.type === 'submit') {
            this.action.emit();
            this.inputArea.nativeElement.blur();
        }
        else if (this.settings.type === 'date') {
            this.inputArea.open();
        }
        else if (this.settings.type === 'select') {
            this.selectOptions.cssClass = this.customCSS;
            this.selectOptions.header = this.settings.placeholder;
            this.inputArea.open();
        }
        else if (this.settings.type === 'search-select') {
            // this.selectOptions.header = this.settings.placeholder;
            if (!this.selectSearch.isOpened) {
                this.selectSearch.open();
            }
        }
        else if (this.settings.type === 'search-select-driver') {
            if (!this.selectSearch.isOpened) {
                this.selectSearch.open();
            }
        }
        else {
            this.inputArea.nativeElement.focus();
        }
    };
    IconInputComponent.prototype.click_SelectableAddDriver = function (first_name, surname) {
        var _this = this;
        if (first_name.settings.value == '' || surname.settings.value == '') {
            alert('You must enter a first name and a surname');
            return false;
        }
        var foundDriver = this.selectSearch.items.find(function (x) { return x.label.toLowerCase() === (first_name.settings.value.toLowerCase() + ' ' + surname.settings.value.toLowerCase()); });
        if (foundDriver) {
            alert('There is a driver that already exists');
            return false;
        }
        else {
            this.alertCtrl = new AlertController();
            this.alertCtrl.create({
                header: 'Add new Driver',
                message: 'Are you sure you want to add ' + first_name.settings.value + ' ' + surname.settings.value + '?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: function () {
                            return false;
                        }
                    },
                    {
                        text: 'Ok',
                        handler: function () {
                            var newItem = {
                                label: first_name.settings.value + ' ' + surname.settings.value,
                                value: { id: -1, first_name: first_name.settings.value, surname: surname.settings.value, customer: 22, creation_source: 2 }
                            };
                            _this.manualAdd.emit(newItem.value);
                            _this.selectSearch.addItem(newItem).then(function () {
                                _this.selectSearch.close();
                                // console.log(this.settings.value)
                                _this.settings.value = newItem;
                                // console.log(this.settings.value)
                                setTimeout(function () {
                                    _this.inputChange();
                                    // const foundObject = this.selectSearch.items.find((x: any) => x.value === -1);
                                    // if (foundObject) {
                                    //   this.selectSearch.deleteItem(foundObject);
                                    // }
                                }, 1);
                            });
                        }
                    }
                ],
                backdropDismiss: false
            }).then(function (alertCtrl) { return alertCtrl.present(); });
        }
    };
    IconInputComponent.prototype.click_SelectableAdd = function (valToTrim) {
        var _this = this;
        // Remove any white space or spaces in registrations
        var val = valToTrim.replace(/\s/g, "");
        val = val.toUpperCase();
        var foundReg = this.selectSearch.items.find(function (x) { return x.label.toLowerCase() === val.toLowerCase(); });
        if (foundReg) {
            alert('There is a vehicle that already exists in the list');
            return false;
        }
        else {
            this.alertCtrl = new AlertController();
            this.alertCtrl.create({
                header: 'Add new Registration',
                message: 'Are you sure you want to add ' + val + '?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: function () {
                            return false;
                        }
                    },
                    {
                        text: 'Ok',
                        handler: function () {
                            _this.manuallyAddVehicle(val);
                            // this.manualAdd.emit(newItem.value);
                            // this.selectSearch.addItem(newItem).then(() => {
                            //   this.selectSearch.close();
                            //   this.settings.value = newItem;
                            //   setTimeout(() => {
                            //     this.inputChange();
                            //     // const foundObject = this.selectSearch.items.find((x: any) => x.value === -1);
                            //     // if (foundObject) {
                            //     //   this.selectSearch.deleteItem(foundObject);
                            //     // }
                            //   }, 1);
                            // });
                        }
                    }
                ],
                backdropDismiss: false
            }).then(function (alertCtrl) { return alertCtrl.present(); });
        }
    };
    IconInputComponent.prototype.focus = function () {
        var _this = this;
        // console.log('Focus Please');
        setTimeout(function () {
            _this.inputArea.nativeElement.focus();
        }, 50);
    };
    IconInputComponent.prototype.manuallyAddVehicle = function (reg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cust, newItem, newVehicle;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataProvider.getUserProfile()];
                    case 1:
                        cust = _a.sent();
                        newItem = {
                            label: reg,
                            value: { id: -1, registration: reg }
                        };
                        newVehicle = {
                            external_id: null,
                            registration: reg,
                            vehicle_type: null,
                            creation_source: 2,
                            customer: cust.customer
                        };
                        // const createdVehicle: any = await this.rest.createVehicle(newVehicle);
                        this.rest.createVehicle(newVehicle).then(function (createdVehicle) {
                            newVehicle.external_id = createdVehicle['id'];
                            newVehicle = tslib_1.__assign({}, newVehicle, createdVehicle);
                            _this.manualAdd.emit(newVehicle);
                            newItem.value.id = newVehicle.external_id;
                            _this.selectSearch.addItem(newItem).then(function () {
                                _this.selectSearch.close();
                                _this.settings.value = newItem;
                                setTimeout(function () {
                                    _this.inputChange();
                                }, 1);
                            });
                        }, function (err) {
                            if (err.status && err.status == 400) {
                                var dataBody = err.error;
                                var errorMessage = '';
                                for (var _i = 0, _a = Object.entries(dataBody); _i < _a.length; _i++) {
                                    var _b = _a[_i], key = _b[0], value = _b[1];
                                    console.log(key + ": " + value);
                                    errorMessage += key + ": " + value[0];
                                }
                                _this.alert = new AlertController();
                                _this.alert.create({
                                    header: 'An issue with this registration',
                                    message: errorMessage,
                                    buttons: [
                                        {
                                            text: 'Cancel',
                                            role: 'cancel',
                                            handler: function () {
                                                return false;
                                            }
                                        }
                                    ],
                                    backdropDismiss: false
                                }).then(function (alertCtrl) { return alertCtrl.present(); });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return IconInputComponent;
}());
export { IconInputComponent };
