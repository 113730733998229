import { Injectable } from '@angular/core';
import { Storage } from "@ionic/storage";
import { CustomerModel } from '../models/user-cust-models';
import { resolve } from 'q';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class DataProvider {

    private static PROFILE_KEY_NAME: string = "user";
    private static LOGIN_INFO_KEY_NAME: string = "LOGIN_INFO";
    private static CUSTOMER_KEY_NAME: string = "customer";

    private static LAST_STORED_REPORT: string = "stored_report";
    private static LAST_STORED_REPORT_DRIVER: string = "stored_report_driver";

    private static REQUIRE_REAUTH: string = "require_reauth";
    public hasGotInitialData: boolean = false;
    constructor(private storage: Storage) {}

    private getDataFromDB(key: string) {
        return this.storage.get(key);
    }

    public getUserProfile() {
        return this.getDataFromDB(DataProvider.PROFILE_KEY_NAME).then(result => {
            return result;
        });
    }

    public getLoginInfo() {
        return this.storage.ready().then(() => {
            return this.storage.get(DataProvider.LOGIN_INFO_KEY_NAME);
        });
    }

    public setCustomer(data: CustomerModel) {
        this.storage.ready().then(() => {
            this.storage.set(DataProvider.CUSTOMER_KEY_NAME, data);
        });
    }

    public getCustomer() {
        return this.storage.ready().then(() => {
            return this.storage.get(DataProvider.CUSTOMER_KEY_NAME);
        });
    }

    public requireReauth(require?: boolean) {
        return this.storage.ready().then(() => {
            
            if (require != null) {
                // Store the state saying we need to reauth.
                console.log(' this.storage.set(DataProvider.REQUIRE_REAUTH, require);', require)
                return this.storage.set(DataProvider.REQUIRE_REAUTH, require);
            } else {
                console.log('this.storage.get(DataProvider.REQUIRE_REAUTH);', require)

                // Either return yes we do or no we dont (stop null return); 
                return this.storage.get(DataProvider.REQUIRE_REAUTH);
            }
        });
    }
}
