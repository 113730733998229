import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IModulePermissions, IRole } from '../models/user-cust-models';
@Injectable()

export class PermissionsProvider {

    public modulePermissions: IModulePermissions = [];
    public role: IRole | IRole[];
    public roleName: string;

    constructor(public http: HttpClient) {
    }

    LoadRole(roles: IRole | IRole[]) {
        this.role = roles instanceof Array ?
            roles.filter((r: IRole) => r.ExternalAppReference === 'DEFECT')
                // make sure we map the rolename check to set the old values
                .map(r => this.RoleNameCheck(r)) : this.RoleNameCheck(roles);

        // if its not an array then return the singluar object
        this.roleName = roles instanceof Array ? this.role[0].name : (this.role as IRole).name;
    }

    /**
     * we need this to check against the inconsistency of names
     * for instance the IsRepairer needs to be is_repairer, this is 
     * temporary for backwards compatibility
     * 
     * so we basically add an is_repairer if the IsRepairer is true
     */
    RoleNameCheck(role: IRole): IRole {
        return {
            ...role,
            name: role.PermissionName,
            is_admin: role.IsGlobalAdmin,
            is_manager: role.IsManager,
            is_repairer: role.IsRepairer,
            is_readonly: role.IsReadonly
        }
    }

    LoadModulePermissions(permissions: IModulePermissions) {
        this.modulePermissions = permissions;
    }

    hasRolePermission(roleKey: string): boolean {
        if (this.role instanceof Array) {
            for (const role of this.role) {
                if (role[roleKey] === true) {
                    return true;
                }
            }
            return false;
        } else {
            return this.role[roleKey] === true;
        }
    }

    isntRolePermission(roleKey: string): boolean {
        if (this.role instanceof Array) {
            for (const role of this.role) {
                if (!role[roleKey]) {
                    return true;
                }
            }
            return false;
        } else {
            return !!this.role[roleKey];
        }
    }

    hasModulePermission(permission: string) {
        let _perm = this.modulePermissions.find(perm => perm.name === permission);
        return _perm != null ? true : false;
    }
}
