import { Router } from '@angular/router';
import { AuthService } from '../providers/auth/auth.service';
import { GlobalVariable } from '../providers/global/global';
import { DataProvider } from '../providers/data/data';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../providers/auth/auth.service";
import * as i2 from "../providers/global/global";
import * as i3 from "@angular/router";
import * as i4 from "../providers/data/data";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, global, router, dataProvider) {
        this.authService = authService;
        this.global = global;
        this.router = router;
        this.dataProvider = dataProvider;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.authService.checkToken().then(function (value) {
            if (_this.authService.authenticationState.getValue()) {
                if (!AuthService.accessToken && _this.authService.token) {
                    AuthService.accessToken = _this.authService.token;
                    _this.authService.user = _this.authService.getUserProfile();
                    _this.dataProvider.getCustomer().then(function (customer) {
                        _this.global.customer = customer;
                    });
                }
                var hasAccessToApp = !!_this.authService.user.extra_vars.permissions.find(function (x) { return x.ExternalAppReference === environment.appReference; });
                if (!hasAccessToApp) {
                    _this.router.navigateByUrl("/restricted");
                }
                return hasAccessToApp;
            }
            else {
                _this.router.navigateByUrl("/login?returnUrl=" + state.url);
                return false;
            }
        }).catch(function (error) {
            return false;
        });
    };
    AuthGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.GlobalVariable), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.DataProvider)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
