/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../components/custom/profile-header/profile-header.component.ngfactory";
import * as i5 from "../../components/custom/profile-header/profile-header.component";
import * as i6 from "../../providers/rest/rest";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./dashboard.page";
import * as i10 from "../../providers/auth/auth.service";
import * as i11 from "../../providers/global/global";
import * as i12 from "../../providers/permissions/permissions";
var styles_DashboardPage = [i0.styles];
var RenderType_DashboardPage = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardPage, data: {} });
export { RenderType_DashboardPage as RenderType_DashboardPage };
export function View_DashboardPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-profile-header", [["routeBase", "/dashboard"]], null, null, null, i4.View_ProfileHeaderComponent_0, i4.RenderType_ProfileHeaderComponent)), i1.ɵdid(3, 245760, null, 0, i5.ProfileHeaderComponent, [i6.RestProvider, i7.Router, i3.NavController], { brand: [0, "brand"], subtitle: [1, "subtitle"], routeBase: [2, "routeBase"], menu: [3, "menu"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ion-content", [["forceOverscroll", "false"], ["has-bouncing", "false"], ["no-bounce", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(5, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { forceOverscroll: [0, "forceOverscroll"], scrollY: [1, "scrollY"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "div", [["slot", "fixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "ion-router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i3.IonRouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i8.Location, i1.ElementRef, i7.Router, i1.NgZone, i7.ActivatedRoute, [3, i3.IonRouterOutlet]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.brandOptions; var currVal_1 = _co.subtitle; var currVal_2 = "/dashboard"; var currVal_3 = _co.menuOptions; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "false"; var currVal_5 = false; _ck(_v, 5, 0, currVal_4, currVal_5); _ck(_v, 8, 0); }, null); }
export function View_DashboardPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardPage_0, RenderType_DashboardPage)), i1.ɵdid(1, 114688, null, 0, i9.DashboardPage, [i3.NavController, i10.AuthService, i11.GlobalVariable, i12.PermissionsProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPageNgFactory = i1.ɵccf("app-dashboard", i9.DashboardPage, View_DashboardPage_Host_0, {}, {}, []);
export { DashboardPageNgFactory as DashboardPageNgFactory };
