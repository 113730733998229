import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActionSheetController, NavController, IonInfiniteScroll, IonRefresher, AlertController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/providers/global/global';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { PermissionsProvider } from '../../../providers/permissions/permissions';
import { RestProvider } from 'src/app/providers/rest/rest';
import { DataProvider } from 'src/app/providers/data/data';
import { Defect, DefectStatus, IDefectNote, IDefectStatus } from '../../../providers/models/user-cust-models';
import { v4 as uuid } from 'uuid';
import { Subject, interval } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
var HomePage = /** @class */ (function () {
    function HomePage(navCtrl, authService, permissionService, global, rest, data, actionSheetController, alertController) {
        var _this = this;
        this.navCtrl = navCtrl;
        this.authService = authService;
        this.permissionService = permissionService;
        this.global = global;
        this.rest = rest;
        this.data = data;
        this.actionSheetController = actionSheetController;
        this.alertController = alertController;
        this.defects = []; // TODO - Needs type/interfacing
        this.refreshInterval = interval(60 * 1000 * 2);
        this.repairers = [];
        this.isLoadingRepairers = false;
        this.defectsLastResultTime = 0;
        this.defectsHasNextPage = '';
        this.searchValid = null;
        this.filterSelected = null;
        this.filterSelectOptions = [{
                label: 'All Defects',
                value: ''
            }];
        this.requireLogin = false;
        this.reauth = {
            username: '',
            email: '',
            password: '',
            autologin: true
        };
        this.reauthName = '';
        this.loadingReauth = false;
        this.addingDefect = false;
        this.depots = [];
        this.repairFilter = {};
        this.depotFilter = {};
        this.regFilter = '';
        this.driverFilter = '';
        this.toogleFilterContainer = false;
        this.defectNumberFilter = '';
        this.defectNumberFullFilter = '';
        this.filterByVehicleTypeSelected = false;
        this.defectStatusObject = DefectStatus;
        this.newSearchCriteria = new Subject();
        this.searchOptions = [{
                selected: false,
                defect: {
                    color: '',
                    icon: '',
                    id: 0,
                    name: 'All'
                }
            }];
        this.authConsentDriver = false;
        this.authConsentManager = false;
        this.loadingSearch = false;
        // Loop through all defect status' and populate the select box.
        Object.values(DefectStatus).forEach(function (status) {
            if (status !== DefectStatus.Complete) {
                if (_this.permissionService.hasRolePermission('is_repairer') && (status.id === 357913941 || status.id === 715827882)) {
                    return;
                }
                _this.searchOptions.push({
                    defect: status,
                    selected: status.name !== 'Repaired'
                });
            }
        });
    }
    HomePage.prototype.Reauthenticate = function () {
        this.reauth.username = this.authService.getUserProfile().extra_vars.email;
        this.global.toast('Dashboard Unlocked.');
        this.data.requireReauth(false);
        this.requireLogin = false;
        this.loadingReauth = false;
        this.reauth.password = '';
        this.authConsentManager = false;
        this.authConsentDriver = false;
    };
    HomePage.prototype.confirmAuth = function (value, type) {
        if (type === 'driver') {
            this.authConsentDriver = value;
        }
        else if (type === 'manager') {
            this.authConsentManager = value;
        }
    };
    HomePage.prototype.ngOnInit = function () {
        this.setupRefreshTimer();
        this.setupFilterListener();
    };
    HomePage.prototype.ionViewDidEnter = function () {
        this.setupRefreshTimer();
        this.setupFilterListener();
    };
    HomePage.prototype.setupFilterListener = function () {
        var _this = this;
        if (!this.$newSearchCriteriaSubscription || this.$newSearchCriteriaSubscription.closed) {
            this.$newSearchCriteriaSubscription = this.newSearchCriteria.pipe(debounceTime(500)).subscribe(function (res) {
                if (_this.depots.length > 1) {
                    _this.getDefects();
                }
                else if (_this.defectsLastResultTime != 0) {
                    _this.global.toast('There are no depots!');
                }
                // console.log('Debounced.');
            });
        }
    };
    HomePage.prototype.setupRefreshTimer = function () {
        var _this = this;
        if (!this.$refreshSub) {
            this.$refreshSub = this.refreshInterval.subscribe(function (res) {
                console.log('-------------------------> Auto refreshing defects');
                _this.getDefects();
            });
        }
    };
    HomePage.prototype.ionViewDidLeave = function () {
        this.cleanup();
    };
    HomePage.prototype.ngOnDestroy = function () {
        this.cleanup();
    };
    HomePage.prototype.cleanup = function () {
        if (this.$newSearchCriteriaSubscription && !this.$newSearchCriteriaSubscription.closed) {
            this.$newSearchCriteriaSubscription.unsubscribe();
        }
        if (this.$refreshSub && !this.$refreshSub.closed) {
            this.$refreshSub.unsubscribe();
        }
    };
    HomePage.prototype.toggleFilter = function () {
        this.toogleFilterContainer = !this.toogleFilterContainer;
    };
    HomePage.prototype.select_FilterChange = function () {
        // console.log('Filter Search: ' + this.filterSelected);
    };
    HomePage.prototype.select_NumberFilterChange = function (numberFilter) {
        if (this.defectNumberFullFilter !== numberFilter) {
            this.defectNumberFullFilter = numberFilter;
            this.newSearchCriteria.next();
        }
    };
    HomePage.prototype.select_RepairFilterChange = function ($event) {
        // console.log('Filter Change:', $event);
        // this.getDefects();
        this.newSearchCriteria.next();
    };
    HomePage.prototype.loadRepairers = function (locations) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var repairersLoaded, allRepairers, repairPromises, _i, locations_1, depot;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                repairersLoaded = 0;
                this.isLoadingRepairers = true;
                allRepairers = [];
                repairPromises = [];
                for (_i = 0, locations_1 = locations; _i < locations_1.length; _i++) {
                    depot = locations_1[_i];
                    // repairPromises.push(this.rest.GetRepairers(depot.id));
                    repairPromises.push(this.rest.GetRepairers(depot.id).then(function (repairers) {
                        var newLoad = { label: "Loading " + ++repairersLoaded + "/" + locations.length + "...", value: null };
                        _this.repairFilter = newLoad;
                        allRepairers = allRepairers.concat(repairers);
                    }));
                }
                Promise.all(repairPromises).then(function () {
                    console.log('End Result', allRepairers);
                    _this.isLoadingRepairers = false;
                    _this.repairers = allRepairers.map(function (x) { return x = {
                        label: x.name,
                        value: x.name
                    }; });
                    var noRepairer = {
                        label: 'All',
                        value: null
                    };
                    _this.repairFilter = noRepairer;
                    _this.repairers.unshift(noRepairer);
                });
                return [2 /*return*/];
            });
        });
    };
    HomePage.prototype.loadDepots = function (locations) {
        var depotFiltered = locations.filter(function (x) { return x.is_depot; });
        depotFiltered.sort(function (a, b) { return a.name > b.name ? 1 : -1; });
        depotFiltered = depotFiltered.map(function (x) { return x = {
            label: x.name,
            value: x.id
        }; });
        this.depots = depotFiltered;
        var none = {
            label: 'All',
            value: 'none'
        };
        this.depots.unshift(none);
        this.depotFilter = none;
    };
    HomePage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loadingPlaceholder, locations, newLoad;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.data.requireReauth().then(function (result) {
                            _this.requireLogin = result;
                        });
                        if (!!this.data.hasGotInitialData) return [3 /*break*/, 2];
                        this.data.hasGotInitialData = true;
                        loadingPlaceholder = { label: 'Initilising...', value: null };
                        this.repairers = [loadingPlaceholder];
                        this.repairFilter = loadingPlaceholder;
                        this.depots = [loadingPlaceholder];
                        this.depotFilter = loadingPlaceholder;
                        return [4 /*yield*/, this.rest.DepotLocations()];
                    case 1:
                        locations = _a.sent();
                        newLoad = { label: "Loading 0/" + locations.length + "...", value: null };
                        this.repairers = [newLoad];
                        this.repairFilter = newLoad;
                        this.loadRepairers(locations);
                        this.loadDepots(locations);
                        _a.label = 2;
                    case 2:
                        this.getDefects();
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.toggleDefect = function (defect) {
        if (defect.id === 0) {
            // If 'All' is toggled the remove all other selections.
            this.searchOptions.forEach(function (element) {
                element.selected = false;
            });
        }
        else {
            // Otherwise toggle the option that we pressed, and also set 'All' to false.
            this.searchOptions[0].selected = false;
            var option = this.searchOptions.find(function (x) { return x.defect === defect; });
            option.selected = !option.selected;
        }
        // We will toggle the All Defects sleection based on if any other selections are made.
        // if nothing is slected then we will receive all defects.
        var anySelected = false;
        this.searchOptions.forEach(function (element) {
            if (element.selected) {
                anySelected = true;
            }
        });
        this.searchOptions[0].selected = !anySelected;
        // this.getDefects();
        this.newSearchCriteria.next();
    };
    HomePage.prototype.doRefresh = function ($event) {
        this.getDefects();
    };
    HomePage.prototype.getDefects = function () {
        var _this = this;
        console.log('getting Defects...', this.depots);
        var wantedSearches = [];
        this.searchOptions.forEach(function (element) {
            if (element.selected && element.defect.id !== 0) {
                wantedSearches.push(element.defect.id);
            }
        });
        console.log('Wanted Searchs', wantedSearches);
        var depots = this.depotFilter.value !== 'none' ? [this.depotFilter.value] : this.depots.map(function (x) { return x = x.value; }).slice(1, this.depots.length);
        this.rest.GetDefects(depots, wantedSearches, this.repairFilter.value, this.driverFilter, this.regFilter, this.filterByVehicleTypeSelected, this.defectNumberFilter, this.defectNumberFullFilter).then(function (res) {
            if (res.timestamp > _this.defectsLastResultTime) {
                _this.defectsLastResultTime = res.timestamp;
            }
            else {
                return;
            }
            _this.defectsHasNextPage = res.next;
            _this.infiniteScroll.disabled = res.next == null;
            _this.global.defectsInLastSearch = res.results;
            _this.defects = res.results;
            console.log('Fetched', res);
            if (res.results.length === 0) {
                _this.searchMessage = 'No defects found';
            }
        })
            .finally(function () {
            if (_this.refresher) {
                _this.refresher.complete();
            }
        });
    };
    HomePage.prototype.filterByVehicleType = function () {
        this.filterByVehicleTypeSelected = !this.filterByVehicleTypeSelected;
        this.newSearchCriteria.next();
    };
    HomePage.prototype.segmentChanged = function (ev) {
    };
    HomePage.prototype.click_signOff = function () {
        // console.log('ssdsdd');
    };
    HomePage.prototype.click_More = function (defect) {
        this.ShowMore(defect);
    };
    HomePage.prototype.ShowMore = function (defect) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uuid, actionSheetOptions, status, actionSheet;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uuid = defect.uuid;
                        actionSheetOptions = {
                            header: 'Actions',
                            buttons: [
                                {
                                    text: 'View Defect',
                                    handler: function () {
                                        _this.ViewDefect(uuid);
                                    }
                                },
                                {
                                    text: 'Close Menu',
                                    role: 'cancel',
                                    cssClass: 'action-cancel',
                                    handler: function () { }
                                }
                            ]
                        };
                        status = this.global.DefectDetails(defect.status);
                        if (!this.permissionService.hasRolePermission('is_readonly')) {
                            if (status !== DefectStatus.Repaired) {
                                actionSheetOptions.buttons.splice(1, 0, {
                                    text: 'View Notes',
                                    handler: function () {
                                        _this.ViewDefectNotes(uuid);
                                    }
                                });
                            }
                            if (status.next_action && !(status === DefectStatus.Signed_Off_Roadworthy && this.permissionService.hasRolePermission('is_repairer'))) {
                                actionSheetOptions.buttons.unshift({
                                    text: status.next_action,
                                    handler: function () {
                                        _this.UpdateDefect(defect.uuid);
                                    },
                                    cssClass: 'action-important'
                                });
                            }
                            console.log(status.id, DefectStatus.At_Repairer.id, DefectStatus.Complete.id);
                            if (status.id == DefectStatus.At_Repairer.id && (this.permissionService.hasRolePermission('is_admin') || this.permissionService.hasRolePermission('is_manager'))) {
                                actionSheetOptions.buttons.splice(1, 0, {
                                    text: 'Transfer Repairer',
                                    handler: function () {
                                        _this.openTransferSheet(defect);
                                    }
                                });
                            }
                        }
                        return [4 /*yield*/, this.actionSheetController.create(actionSheetOptions)];
                    case 1:
                        actionSheet = _a.sent();
                        return [4 /*yield*/, actionSheet.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.openTransferSheet = function (defect) {
        var _this = this;
        this.rest.setIsLoading(true);
        this.rest.GetRepairers(defect.location.external_id).then(function (res) {
            var actionSheetOptions = {
                header: 'Transfer Repairer',
                buttons: [{
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'action-cancel',
                        handler: function () { }
                    }]
            };
            console.log('Repairers', res, defect);
            res.forEach(function (repairer) {
                console.log();
                if (repairer.id != defect.repairer.id) {
                    actionSheetOptions.buttons.unshift({
                        text: repairer.name,
                        handler: function () {
                            _this.confirmTransferDepot(defect, repairer);
                        }
                    });
                }
            });
            _this.rest.setIsLoading(false);
            _this.actionSheetController.create(actionSheetOptions).then(function (a) { return a.present(); });
        });
    };
    HomePage.prototype.confirmTransferDepot = function (defect, repairer) {
        var _this = this;
        this.alertController.create({
            header: 'Repairer Transfer',
            message: 'Are you sure you want to transfer defect "' + defect.location_ref_number + "-" + defect.defect_number + '" to "' + repairer.name + '" repairer?',
            buttons: [
                {
                    text: 'No',
                    handler: function () {
                        console.log('No');
                    }
                },
                {
                    text: 'Yes',
                    handler: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.transferDefectToAnotherDepot(defect, repairer);
                            return [2 /*return*/];
                        });
                    }); }
                }
            ],
            backdropDismiss: false
        }).then(function (alert) { return alert.present(); });
    };
    HomePage.prototype.transferDefectToAnotherDepot = function (defect, repairer) {
        var _this = this;
        this.rest.setIsLoading(true);
        // Set the defect in the homepage list with the new repairer
        defect.repairer = {
            name: repairer.name,
            external_id: repairer.id
        };
        var defectChange = {
            uuid: defect.uuid,
            status: defect.status,
            repairer: {
                name: repairer.name,
                external_id: repairer.id
            }
        };
        this.rest.DefectUpdate(defectChange).then(function () {
            // Make the defect list reload
            _this.newSearchCriteria.next();
            _this.rest.setIsLoading(false);
            var defectAction = {
                defect: defectChange.uuid,
                comment: "Transferred defect to " + repairer.name,
                user_generated: false,
                files: []
            };
            _this.rest.DefectNotePost(defectAction).then(function (result) {
            }).catch(function (error) {
                _this.displayApiError(error);
            });
        }).catch(function (error) {
            _this.displayApiError(error);
        });
        ;
    };
    HomePage.prototype.displayApiError = function (errorResponse) {
        var message = '';
        switch (errorResponse.status) {
            case 400:
                message = errorResponse.error[0];
                break;
            case 500:
                message = 'An error has occurred';
                break;
            default:
                message = 'There was an issue submitting the defect';
                break;
        }
        this.global.toast(message);
    };
    HomePage.prototype.click_AddDefect = function () {
        this.AddNewDefect();
    };
    HomePage.prototype.click_ViewDefect = function (uuid) {
        this.ViewDefect(uuid);
    };
    HomePage.prototype.click_UpdateDefect = function (uuid) {
        this.UpdateDefect(uuid);
    };
    HomePage.prototype.AddNewDefect = function () {
        var _this = this;
        this.addingDefect = true;
        var defect = { uuid: uuid() };
        this.rest.DefectCreate(defect).then(function (result) {
            var returnedDefect = result;
            _this.addingDefect = false;
            _this.navCtrl.navigateForward("/dashboard/defect/add/" + returnedDefect.uuid);
        }).catch(function (error) {
            _this.global.toast('Failed to add new defect...');
            _this.addingDefect = false;
        });
    };
    HomePage.prototype.ViewDefect = function (uuid) {
        this.navCtrl.navigateForward("/dashboard/defect/view/" + uuid);
    };
    HomePage.prototype.ViewDefectNotes = function (uuid) {
        this.navCtrl.navigateForward("/dashboard/defect/notes/" + uuid);
    };
    HomePage.prototype.UpdateDefect = function (uuid) {
        this.navCtrl.navigateForward("/dashboard/defect/update/" + uuid);
    };
    HomePage.prototype.loadData = function () {
        var _this = this;
        console.log('LOADING MORE');
        this.rest.GetNextDefectsPage(this.defectsHasNextPage).then(function (res) {
            var defects = _this.defects.concat(res.results);
            _this.defectsHasNextPage = res.next;
            _this.infiniteScroll.disabled = res.next === '';
            _this.global.defectsInLastSearch = defects;
            _this.defects = defects;
        })
            .finally(function () {
            _this.infiniteScroll.complete();
        });
    };
    return HomePage;
}());
export { HomePage };
