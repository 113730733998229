<ion-header>
  <ion-toolbar>
    <ion-title><strong>{{is_top_three ? 'Previous' : 'Open'}} Defects for {{registration}}</strong></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true">Continue</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="">
  <section class="onboard-wrap onboard-wrap__vertical onboard-results">
    <ion-list>
      <ion-item-group *ngIf="is_top_three">
        <ion-item-divider>
          <ion-label>Three Most Recent Defects</ion-label>
        </ion-item-divider>
        <ion-item *ngFor="let defect of top_three">
          <ion-label class="c-driver-item">
            <div class="c-driver-item--side">
              <div class="c-driver-image"
                [ngStyle]="{'border-color': 'var(--ion-color-' + global.DefectDetails(defect.status).color + ')'}">
                <ion-icon [name]="global.DefectDetails(defect.status).icon"
                  [color]="global.DefectDetails(defect.status).color"></ion-icon>
              </div>
              <div class="c-driver-details">
                <span class="c-driver-details--name">
                  <p *ngIf="defect.defect_number">#{{defect.location_ref_number}}-{{defect.defect_number}},</p>
                  <p>{{defect.driver_printed_name != '' ? defect.driver_printed_name : 'No driver name'}}</p>
                  <span class="c-driver-details--alert" *ngIf="defect.vor === true" title="VOR">!</span>
                </span>
                <span class="c-driver-details--license">
                  Created : {{defect.server_created_date | date:'dd/MM/yyyy HH:mm'}}
                  {{defect.server_user_notes_count ? " - " + defect.server_user_notes_count + ' note(s)' : ''}}
                  <span class="c-driver-details--repairer"
                    *ngIf="defect.status === defectStatusObject.At_Repairer.id">{{defect.repairer?.name}}</span>
                  <br>
                  Updated : {{defect.server_last_updated | date:'dd/MM/yyyy HH:mm'}}
                </span>
              </div>
            </div>
            <!-- More Buttons + Actions -->
            <div class="c-driver-item--side c-driver-item--side--stacked c-driver-item--side__end">
              <div class="c-driver-actions">
                <span class="c-driver-details--categories" *ngIf="defect.defect_details">
                  <p *ngFor="let details of defect.defect_details">
                    {{details.category}}
                  </p>
                </span>
              </div>

              <ion-button *ngIf="defect.id !== currentDefect.id" size="small" fill="clear" (click)="click_view(defect)">
                view 
                <ion-icon slot="end" name="arrow-forward"></ion-icon>
              </ion-button>
              <ion-chip style="font-size: 13px;" color="tertiary" *ngIf="defect.id === currentDefect.id" >
                Current Defect
              </ion-chip>

            </div>
          </ion-label>

        </ion-item>
      </ion-item-group>

      <ion-item-group *ngIf="defects.length > 0">
        <ion-item-divider>
          <ion-label>{{is_top_three ? 'Other ' : ''}} Open Defects </ion-label>
        </ion-item-divider>
        <ion-item *ngFor="let defect of defects">
          <ion-label class="c-driver-item">
            <div class="c-driver-item--side">
              <div class="c-driver-image"
                [ngStyle]="{'border-color': 'var(--ion-color-' + global.DefectDetails(defect.status).color + ')'}">
                <ion-icon [name]="global.DefectDetails(defect.status).icon"
                  [color]="global.DefectDetails(defect.status).color"></ion-icon>
              </div>
              <div class="c-driver-details">
                <span class="c-driver-details--name">
                  <p *ngIf="defect.defect_number">#{{defect.location_ref_number}}-{{defect.defect_number}},</p>
                  <p>{{defect.driver_printed_name != '' ? defect.driver_printed_name : 'No driver name'}}</p>
                  <span class="c-driver-details--alert" *ngIf="defect.vor === true" title="VOR">!</span>
                </span>
                <span class="c-driver-details--license">
                  Created : {{defect.server_created_date | date:'dd/MM/yyyy HH:mm'}}
                  {{defect.server_user_notes_count ? " - " + defect.server_user_notes_count + ' note(s)' : ''}}
                  <span class="c-driver-details--repairer"
                    *ngIf="defect.status === defectStatusObject.At_Repairer.id">{{defect.repairer?.name}}</span>
                  <br>
                  Updated : {{defect.server_last_updated | date:'dd/MM/yyyy HH:mm'}}
                </span>
              </div>
            </div>
            <!-- More Buttons + Actions -->
            <div class="c-driver-item--side c-driver-item--side--stacked c-driver-item--side__end">
              <div class="c-driver-actions">
                <span class="c-driver-details--categories" *ngIf="defect.defect_details">
                  <p *ngFor="let details of defect.defect_details">
                    {{details.category}}
                  </p>
                </span>
              </div>
              <ion-button size="small" fill="clear" (click)="click_view(defect)">
               view 
               <ion-icon slot="end" name="arrow-forward"></ion-icon>
              </ion-button>
             
            </div>
          </ion-label>

        </ion-item>
        <div *ngIf="defects.length == 0 && !isLoading">
          <div class="c-message c-message--info">
            <h3 class="c-message__heading">There are no other open defects for {{registration}}. Great job!</h3 >
          </div>
        </div>
      </ion-item-group>
    </ion-list>
  </section>

  <!-- <div class="c-home-search-message" *ngIf="defects?.length == 0">
    <p>{{ searchMessage }}</p>
  </div> -->
  <span class="c-defect-repair-spinner" *ngIf="isLoading">
    <ion-spinner name="crescent"></ion-spinner>
  </span>


</ion-content>