<section class="c-profile-head">
    <div class="c-profile-head--side">
        <img class="c-brand-logo" src="{{brand.logoUrl}}" alt="Logo">
        <div class="c-brand-details">
            <span class="c-brand-details--name">
                {{brand.name}}
            </span>
            <span class="c-brand-details--section" *ngIf="this.router.url === '/defect'; else dashboard">
                New Defect
            </span>
            <ng-template #dashboard>
                <span class="c-brand-details--section">{{subtitle}}</span>
            </ng-template>
        </div>
    </div>
    <div class="c-profile-head--side c-profile-head--side__end">
        <app-user-dropdown *ngIf="menu" [options]="menu"></app-user-dropdown>
    </div>
</section>
<section [ngClass]="{'open': canGoBack}" class="c-profile-history" *ngIf="this.router.url !== '/defect'">
    <span class="c-profile-history--back" (click)="goBack()">
      <ion-icon name="md-arrow-back"></ion-icon>
      Back
    </span>
</section>
<section class="c-profile-head-load" *ngIf="isLoading">
    <span class="c-profile-head-load--bar"></span>
</section>
