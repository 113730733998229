<div class="icon-input" (click)="click_Root()"
  [ngClass]="[settings.styles != 'light' ? settings.styles : '', settings.loading ? 'loading' : '', settings.enabled ? '' : 'disabled', settings.readonly ? 'readonly' : '']">

  <!-- Icon left of Input Box -->
  <ion-icon class="icon-input--type" *ngIf="settings.icon && settings.type != 'button'">
  </ion-icon>

  <div class="input-prefix"
    *ngIf="prefix && settings.type != 'date' && settings.type != 'select' && settings.type != 'toggle' && (settings.type != 'search-select' || !settings.value) && (settings.type != 'search-select-driver' || !settings.value)">
    {{prefix}}
  </div>
  <!-- Input Box -->
  <input
    *ngIf="settings.type != 'date' && settings.type != 'select' && settings.type != 'toggle' && (settings.type != 'search-select' || !settings.value) && (settings.type != 'search-select-driver' || !settings.value)"
    [disabled]="!settings.enabled || settings.type == 'search-select' || settings.type == 'search-select-driver'"
    class="icon-input--input" [(ngModel)]="settings.value" (ngModelChange)="inputChange()" [title]="settings.title" [pattern]="settings.inputPattern"
    [autocomplete]="settings.autocomplete" [placeholder]="settings.placeholder" [type]="settings.type" #inputArea>

  <ion-datetime *ngIf="settings.type == 'date'" [(ngModel)]="settings.value" (ngModelChange)="inputChange()" [max]="max"
    [min]="min" displayFormat="DD/MM/YYYY" #inputArea></ion-datetime>
  <ion-select *ngIf="settings.type == 'select'" placeholder="{{settings.placeholder}}"
    [interfaceOptions]="selectOptions" interface="popover" [(ngModel)]="settings.value" (ngModelChange)="inputChange()"
    okText="Select" cancelText="Dismiss" #inputArea>
  <ion-select-option *ngFor="let option of settings.options" [disabled]="option.disabled" [value]="option.value">
      {{option.label}}</ion-select-option>
  </ion-select>

  <ionic-selectable *ngIf="settings.type == 'search-select'" [(ngModel)]="settings.value" [items]="settings.options"
    itemValueField="value" itemTextField="label" [canSearch]="true" (onSelect)="select_SelectableAction($event)"
    (onChange)="inputChange()" [ngStyle]="{'width': settings.value ? '100%' : '20px'}" [hasVirtualScroll]="true"
    #inputSelectSearch>
    <ng-template *ngIf="settings.selectFailAdd" ionicSelectableSearchFailTemplate>
      <div padding class="icon-input-selectable-list title" style="padding-bottom:0;">
        <h3 style="text-align: center; width: 100%;">No vehicle was found.</h3>
      </div>
    </ng-template>
    <ng-template ionicSelectableFooterTemplate *ngIf="selectSearch?.searchText?.length > 1 && settings.selectFailAdd">
      <ion-toolbar>
        <ion-row>
          <ion-col>
            <div style="padding: 4px 2px 0 4px;">Do you want to create a new vehicle with:
              <strong>{{selectSearch.searchText}}</strong>
            </div>
            <div class="error-message" *ngIf="selectSearch?.searchText?.length > 20">Can't be longer than 20 characters</div>
          </ion-col>
          <ion-col style="max-width: 200px;">
            <icon-input value="Create New" [enabled]="selectSearch?.searchText?.length <= 20" (action)="click_SelectableAdd(selectSearch.searchText)" type="button"
              styles="dark"></icon-input>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ng-template>
  </ionic-selectable>

  <ionic-selectable *ngIf="settings.type == 'search-select-driver'" [(ngModel)]="settings.value"
    [items]="settings.options" itemValueField="value" itemTextField="label" [canSearch]="true"
    (onSelect)="select_SelectableActionDriver($event)" (onChange)="inputChange()"
    [ngStyle]="{'width': settings.value ? '100%' : '20px'}" [hasVirtualScroll]="true" #inputSelectSearch>
    <ng-template *ngIf="settings.selectFailAdd" ionicSelectableSearchFailTemplate>
      <div padding class="icon-input-selectable-list title" style="padding-bottom:0;">
        <h3 style="text-align: center; width: 100%;">No driver was found. Add your name below into the input fields.
        </h3>
      </div>
    </ng-template>
    <ng-template ionicSelectableFooterTemplate *ngIf="selectSearch?.searchText?.length > 1 && settings.selectFailAdd">
      <ion-toolbar>
        <ion-row>
          <ion-col>
            <div style="padding: 4px 2px 0 4px;">Do you want to create a new driver?
              <!-- <strong>{{selectSearch.searchText}}</strong> -->
              <br>
              <div align="right">
                <icon-input #fname placeholder="Enter first name" type="text" icon="person"></icon-input>
                <!-- <label for="fname">First Name:</label>
                <input #fname type="text" id="fname" name="fname"><br>
                <label for="sname">Surname:</label>
                <input #sname type="text" id="sname" name="sname"><br> -->
              </div>
              <div style="padding-top: 5px">
                <icon-input #sname placeholder="Enter surname" style="padding-top: 4em;" type="text" icon="person">
                </icon-input>
              </div>
            </div>
          </ion-col>
          <ion-col style="max-width: 200px; padding-top: 50px;">
            <icon-input value="Create New" (action)="click_SelectableAddDriver(fname, sname)" type="button"
              styles="dark">
            </icon-input>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ng-template>
  </ionic-selectable>

  
  <!-- Checkmark to right of input box -->
  <ion-icon *ngIf="settings.type != 'button'" class="icon-input--verify"
    [name]="settings.verify ? 'checkmark' : 'close'"
    [ngClass]="{'icon-input--verify__good': settings.verify, 'icon-input--verify__bad': !settings.verify, 'icon-input--verify__hide': settings.verify==null}">
  </ion-icon>
  <!-- Button Icon to right -->
  <ion-icon *ngIf="settings.icon && settings.type == 'button' && !settings.loading"
    class="icon-input--verify icon-input--verify__good" [name]="settings.icon"></ion-icon>

  <!-- Delete Icon to right -->
  <ion-icon *ngIf="settings.type == 'input'" class="icon-input--verify"
    [name]=" settings.value.length > 1 ? 'close' : null"
    [ngClass]="{'icon-input--verify__close': settings.value.length > 1, 'icon-input--verify__hide': settings.value.length < 2 }"
    (click)="settings.value = ''; inputChange()"></ion-icon>

  <!-- Spinner if loading. -->
  <ion-spinner class="icon-input--spinner" *ngIf="settings.loading" name="crescent"></ion-spinner>
</div>
