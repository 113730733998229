import * as tslib_1 from "tslib";
import { Camera } from '@ionic-native/camera/ngx';
import { Platform } from '@ionic/angular';
import { reject } from 'q';
var MediaProvider = /** @class */ (function () {
    function MediaProvider(platform, camera) {
        this.platform = platform;
        this.camera = camera;
        this.id = 'fileinput-' + Math.random().toString(36).substr(0, 9);
    }
    MediaProvider.prototype.isMediaStreamSupported = function () {
        return navigator.mediaDevices != undefined;
    };
    MediaProvider.prototype.getPhotos = function (multiple) {
        return this.getBrowserPhotos(multiple);
    };
    MediaProvider.prototype.urlToData64 = function (url) {
        return new Promise(function (res, rej) {
            fetch(url).then(function (response) { return response.blob(); }).then(function (blob) {
                var reader = new FileReader();
                reader.onloadend = function () { return res(reader.result); };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }).catch(function (error) {
            reject(error);
        });
    };
    MediaProvider.prototype.fileToData64 = function (file) {
        return new Promise(function (res, rej) {
            var reader = new FileReader();
            reader.onload = function (data) {
                res(data.target.result);
            };
            reader.readAsDataURL(file);
        });
    };
    MediaProvider.prototype.data64ToFile = function (data64, filename) {
        if (filename === void 0) { filename = "Photo"; }
        return new Promise(function (res, rej) {
            var blobBin = atob(data64.split(',')[1]);
            var array = [];
            for (var i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }
            var blob = new Blob([new Uint8Array(array)], { type: 'image/png' });
            var file = new File([blob], filename, { type: 'image/png', lastModified: Date.now() });
            res(file);
        });
    };
    MediaProvider.prototype.getPhotosDataURL = function (multiple) {
        var _this = this;
        return new Promise(function (res, rej) {
            _this.getPhotos(multiple).then(function (result) {
                var dataArray = [];
                var reader = new FileReader();
                reader.onload = function (data) {
                    dataArray.push(data.target.result);
                    if (dataArray.length == result.length) {
                        res(dataArray);
                    }
                };
                for (var i = 0; i < result.length; i++) {
                    reader.readAsDataURL(result[i]);
                }
            });
        });
    };
    MediaProvider.prototype.getDevicePhoto = function (sourceType) {
        var _this = this;
        // Mobile so show camera
        var options = {
            quality: 60,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            correctOrientation: true,
            sourceType: sourceType,
        };
        return new Promise(function (res, rej) {
            _this.camera.getPicture(options).then(function (imageData) {
                var base64Image = 'data:image/jpeg;base64,' + imageData;
                res(base64Image);
            }, function (err) {
                rej("Error getting photo.");
            });
        });
    };
    MediaProvider.prototype.getBrowserPhotos = function (multiple, types) {
        return new Promise(function (res, rej) {
            var element = document.createElement("input");
            if (multiple) {
                element.setAttribute("multiple", "true");
            }
            element.type = "file";
            element.accept = types || 'image/*';
            document.body.appendChild(element);
            element.onchange = function () {
                res(element.files);
                element.remove();
            };
            element.click();
        });
    };
    MediaProvider.prototype.canMediaStream = function () {
        return location.protocol == 'https';
    };
    MediaProvider.prototype.getMediaStream = function (video, audio, preferFront) {
        if (video === void 0) { video = true; }
        return new Promise(function (res, rej) {
            var consts = {
                video: preferFront ? { facingMode: "user" } : { facingMode: "environment" },
                audio: audio
            };
            navigator.mediaDevices.getUserMedia(consts).then(function (stream) {
                var mediaStream = stream;
                MediaProvider.activeStreams.push(mediaStream);
                res(mediaStream);
            }).catch(function (error) {
                rej(error);
            });
        });
    };
    MediaProvider.prototype.stopAllMediaStream = function () {
        MediaProvider.activeStreams.forEach(function (stream) {
            var tracks = stream.getTracks();
            tracks.forEach(function (track) { track.stop(); });
        });
        MediaProvider.activeStreams = [];
    };
    MediaProvider.prototype.captureFrameFromStream = function (stream, resolution) {
        var _this = this;
        return new Promise(function (res, rej) {
            var element = document.createElement("video");
            element.style.visibility = "hidden";
            document.body.appendChild(element);
            element.srcObject = stream;
            element.play();
            setTimeout(function () {
                _this.captureFrameFromVideo(element, resolution).then(function (data) { return res(data); }).catch(function (error) { return rej(error); });
            }, 100);
        });
    };
    MediaProvider.prototype.captureFrameFromVideo = function (video, resolution) {
        return new Promise(function (res, rej) {
            if (!resolution) {
                resolution = { x: video.videoWidth, y: video.videoHeight };
            }
            var canvas = document.createElement("canvas");
            canvas.style.visibility = "hidden";
            document.body.appendChild(canvas);
            var context = canvas.getContext('2d');
            context.canvas.width = resolution.x;
            context.canvas.height = resolution.y;
            context.drawImage(video, 0, 0, resolution.x, resolution.y);
            var data = canvas.toDataURL();
            res(data);
            canvas.remove();
        });
    };
    MediaProvider.prototype.stopMediaStream = function (stream) {
        var tracks = stream.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });
        MediaProvider.activeStreams.forEach(function (stream) {
            var tracks = stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
        });
    };
    MediaProvider.prototype.generateRandomGradiant = function (width, height) {
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        var gradiant = ctx.createLinearGradient(0, 0, canvas.width, 0);
        gradiant.addColorStop(0, this.generateRandomHex());
        gradiant.addColorStop(1, this.generateRandomHex());
        ctx.fillStyle = gradiant;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        return canvas.toDataURL();
    };
    MediaProvider.prototype.generateRandomHex = function () {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    /**
     * @description Convert a Web URL or File to an image object
     * @returns Image object with the URL as it's source
     */
    MediaProvider.prototype.convertToImage = function (data) {
        return new Promise(function (res) {
            var img = new Image();
            img.onload = function () {
                res(img);
            };
            img.src = URL.createObjectURL(data);
        });
    };
    /**
     * @description Resizes an image based off of given constraints
     * @param image
     * @param options
     * @returns
     */
    MediaProvider.prototype.resizeImage = function (image, options) {
        return new Promise(function (res) {
            var canvas = document.createElement('canvas');
            var width = typeof options.size === 'number' ? options.size : options.size.w;
            var height = typeof options.size === 'number' ? options.size : options.size.h;
            if (options.mode === 'exact') {
                // Do nothing
            }
            else if (options.mode === 'scale') {
                width = image.width * width;
                height = image.height * height;
            }
            else if (options.mode === 'clamp') {
                if (width && height) {
                    // Do nothing
                }
                else if (width && !height) {
                    height = image.height * (width / image.width);
                }
                else if (!width && height) {
                    width = image.width * (height / image.height);
                }
            }
            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            if (options.output === 'blob') {
                canvas.toBlob(function (e) {
                    res(e);
                }, options.type);
            }
            else {
                res(canvas.toDataURL(options.type));
            }
        });
    };
    /**
     * @description Converts a photo to a file
     * @param url URL of the photo to convery
     * @param name Name of the file to be output
     * @returns
     */
    MediaProvider.prototype.photoToFile = function (url, name) {
        var _this = this;
        return new Promise(function (res) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var file;
                return tslib_1.__generator(this, function (_a) {
                    file = xhr.response;
                    file.lastModifiedDate = new Date();
                    file.name = name;
                    res(file);
                    return [2 /*return*/];
                });
            }); };
            xhr.send();
        });
    };
    /**
     * @description Converts a data url into a file format.
     * @param dataurl
     * @param filename
     * @returns
     */
    MediaProvider.prototype.dataURLtoFile = function (dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    MediaProvider.activeStreams = [];
    return MediaProvider;
}());
export { MediaProvider };
