export class CustomerModel {
  public id: number;
  public name: string;
  public logo?: string; // 64base
  public logo_icon?: string; // 64base
  public customer_config: CustomerConfigModel;
}

export class CustomerConfigModel {
  public customer: string;
  public id: number;
  public notice_letter_driver_categories_permission: string[];
}

export class LoginInfoModel {
  public username: string;
  public email: string;
  public password: string;
  public autologin: boolean
}

export class UserProfileModel {
  public customer: number;
  public email: string;
  public first_name: string;
  public id: number;
  public last_name: string;
  public modules: IModulePermissions;
  public permission: IRole;
  public prefs: Object
  public short_name: string;
}

export class UserInfoProfileModel {
  public aud: string[];
  public auth_time: string;
  public extra_vars: UserInfoExtraVar;
  public iat?: number;
  public iss?: string;
  public rat?: number;
  public sub?: string;

  // public customer: number;
  // public email: string;
  // public first_name: string;
  // public id: number;
  // public last_name: string;
  // public modules: IModulePermissions;
  // public permission: IRole;
  // public prefs: Object
  // public short_name: string;
}

export class UserInfoExtraVar {
  public customerId: number;
  public email: string;
  public firstName: string;
  public id: number;
  public lastName: string;
  public permissions: IRole[];
}



export interface IModuleItem {
  id: number;
  name: string;
}

export interface IModulePermissions extends Array<IModuleItem> { }

export interface IRole {
  /**
   * old interface structure
   * needed for backwards compatibility
   */
  is_admin: boolean;
  is_manager: boolean;
  is_repairer: boolean;
  is_readonly: boolean;
  name: string;
  /**
   * new interface structure from hydra app
   */
  ExternalAppId: number;
  ExternalAppName: string;
  ExternalAppReference: string
  Id: number;
  IsCustomerAdmin: boolean;
  IsEngineer: boolean;
  IsGlobalAdmin: boolean;
  IsManager: boolean;
  IsServerUser: boolean;
  IsRepairer: boolean;
  IsReadonly: boolean;
  IsUserVehicleAdmin: boolean;
  Level: number;
  PermissionName: string;
}

export class ExternalVehicle {
  external_id?: number = 0;
  registration?: string;
  vehicle_type?: string;
  vehicle_type_name?: string; // TODO investigate why this is returning vehicle_type_name
}

export class ExternalVehicleGroup {
  external_id: number;
}


export class ExternalCustomer {
  external_id: number;
  external_name: string;
}

export class ExternalUser {
  external_id: number;
}

export class Location {
  external_id: number = 0;
  name: string = '';
}

export class Defect {
  defect_details?: DefectDetails[];
  status?: number;
  manager_signature?: string;
  manager_printed_name?: string;
  driver_signature?: string;
  driver_printed_name?: string;
  odometer?: number;
  repairer_odo_reading?: number;
  vehicle?: ExternalVehicle = new ExternalVehicle();
  client_created_date?: string;
  uuid?: string;
  defect_number?: number;
  driver_email?: string;
  repairer?: DefectRepairer;
  location?: Location;
  vor?: boolean;
  complete_signature?: string;
  complete_printed_name?: string;
  question_responses?: IDriverQuestionResponse[];
  // everything with server are readonly it is safe to send to server but they will be ignored
  server_customer?: ExternalCustomer;
  server_vehicle_registration_when_created?: string;
  server_repairer_notes_count?: number;
  server_total_notes_count?: number;
  server_user?: ExternalUser;
  server_created_date?: string;
  server_last_updated?: string;

}
export class DefectRepairer {
  external_id: number;
  id?: number;
  name: string;
  requires_manager_auth?: boolean;
  technician_auth_behavior?: string;
}

export class DefectDetails {
  defect?: string = '';
  status?: number = null;
  details?: string = '';
  uuid: string = '';
  server_created_date?: string = '';
  server_last_updated?: string = '';
  server_user?: ExternalUser;
  images?: DefectDetailsImages[];
  new_signoff_action_required?: boolean;
  category?: string = '';
  sub_category?: string = '';
  vor?: boolean = false;
  index: number;
  needs_further_work: boolean;
}

export class DefectDetailsImages {
  uuid?: string;
  image: string;
}

export class PaginatedResult {
  count: number;
  next: string;
  previous: string;
  results: any[];
}

export class DefectCategories {
  category: string;
  template_defect_sub_categories?: DefectSubCategories[];
  label?: string;
  value?: any;
  empty_sub_cat: boolean;
}

export class DefectSubCategories {
  sub_category: string;
  vor_vehicle: boolean;
  label?: string;
  value?: any;
}

export interface IDefectStatus {
  id: number;
  name: string;
  color: string;
  icon: string;
  next_action?: string;
}

export interface IDefectNote {
  defect: string;
  defect_detail?: string;
  uuid?: string;
  comment: string;
  user_generated: boolean;
  signature?: AddedSignature;
  images?: any[];
  files?: any[];

  defect_repair_status?: string;
  defect_details_signoff?: boolean;
  tech_pin?: string;
  needs_further_work?: boolean;

  server_user_uname?: string;
  server_user_fname?: string;
  server_user_sname?: string;
  server_created_date?: string;
  server_last_updated?: string;
  server_user?: {
    external_id: number;
  };
}

export const DefectStatus = {
  Partial_Non_Verified: {
    id: 357913941,
    name: "Awaiting Manager Sign off",
    color: "warning",
    icon: "flag",
    next_action: 'Sign Off'
  },
  Verified_Signed_Off: {
    id: 715827882,
    name: "Verified - Signed Off",
    color: "medium",
    icon: "clipboard",
    next_action: 'Assign Repairer/Workshop'
  },
  // Pending: {
  //   id: 1000000000,
  //   name: "Pending",
  //   color: "danger",
  //   icon: "refresh",
  // },
  At_Repairer: {
    id: 1431655764,
    name: "Repairer Name",
    color: "secondary",
    icon: "construct",
    next_action: 'Repair Defect'
  },
  Signed_Off_Roadworthy: {
    id: 1610612734,
    name: "Workshop attention required - Vehicle Roadworthy",
    color: "tertiary",
    icon: "car",
    next_action: 'Return to Repairer'
  },
  Repaired: {
    id: 1521134249,
    name: "Repaired",
    color: "success",
    icon: "done-all",
    next_action: ''
  },
  Complete: {
    id: 1789569705,
    name: "Returned to Service",
    color: "success",
    icon: "done-all",
  }
};

export class AddedSignature {
  signature: string;
  printed_name: string;
  reason?: string;
  tech_pin?: number;
  defect_repair_status?: string;
  defect_details_signoff?: boolean;
}

export interface IVehicle {
  archived: boolean;
  creation_datetime: string;
  fleet_id: string;
  id: number;
  latest_odometer_date: string;
  latest_odometer_reading: any;
  latest_odometer_source: string;
  registration: string;
  vehicle_group: number;
  vin: string;
}

export interface IDepot {
  archived: boolean,
  customer: number,
  id: number,
  is_depot: boolean,
  is_hub: boolean,
  is_linehaul: boolean,
  name: string,
}
export interface IDepotConfig {
  id: number,
  allow_defects: boolean,
  vehicle_group: number,
  warning: {
    id: number,
    message: string,
    heading: string,
  }
}
export interface IDepotConfigResponse {
  count: number
  next: null | string
  previous: null | string
  results: IDepotConfig[]
}
export interface IDriverQuestionsList {
  required: boolean,
  customer: number,
  id: number,
  questions: IDriverQuestion[]
}
export interface IDriverQuestion {
  id: number;
  text: string;
  question_type: DriverQuestionType;
  questions_list: number;
}
enum DriverQuestionType {
  bool,
  text
}

export interface IDriverQuestionResponse {
  defect: number;
  question: number;
  question_text: string;
  bool_answer: boolean;
  text_answer: string;
}

export interface IDriverQuestionResponsePatch {
  question: number;
  bool_answer: boolean;
}


export interface IDefectDetailsCategories {
  id: number;
  category: string;
  customer: number;
  order: number;
  template_defect_sub_categories: IDefectDetailsSubCategory[]
}

export interface IDefectDetailsSubCategory {
  id: number;
  sub_category: string;
  vor_vehicle: boolean;
  order: number;
  category: number;
}
export interface ITechnician {
  id: number;
  archived: boolean;
  denied_vehicle_types: IVehicleType[];
  name: string;
  repairer: number;
  require_tech_pin_for_repair: boolean;
}
export interface IVehicleType {
  id: number;
  name: string;
  reference: string;
}

export enum LogoutType {
  LoggedOut = "loggedout",
  NotAuthorised = "notauthorised",
  ReAuthenticate = "reauth",
}