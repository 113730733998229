import { OnInit } from '@angular/core';
var UserDropdownComponent = /** @class */ (function () {
    function UserDropdownComponent() {
        this.open = false;
        this._options = {
            header: "Dropdown",
            items: [
                {
                    label: "Item One",
                    action: function () { console.log("Hello One!"); }
                },
                {
                    label: "Item Two",
                    action: function () { console.log("Hello Two!"); }
                },
                {
                    label: "Item Three",
                    action: function () { console.log("Hello Three!"); },
                    icon: 'ios-arrow-round-forward'
                }
            ],
            seperateLast: true
        };
    }
    Object.defineProperty(UserDropdownComponent.prototype, "options", {
        set: function (value) {
            this._options = value;
        },
        enumerable: true,
        configurable: true
    });
    UserDropdownComponent.prototype.ngOnInit = function () { };
    UserDropdownComponent.prototype.click_ToggleMenu = function () {
        this.open = !this.open;
    };
    return UserDropdownComponent;
}());
export { UserDropdownComponent };
