import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { GlobalVariable } from '../global/global';
import { DataProvider } from '../data/data';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LogoutType } from '../models/user-cust-models';


@Injectable()
export class DataliveHttpInterceptor implements HttpInterceptor {

    dataProvider: DataProvider;
    jwt: string;

    constructor(
        private injector: Injector,
        private global: GlobalVariable,
        private router: Router,
        private authService: AuthService
    ) {
        setTimeout(() => {
            this.dataProvider = this.injector.get(DataProvider);
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.authService.getAuthCookie())
            .pipe(
                switchMap(
                    res => {
                        let authToken = res;
                        AuthService.accessToken = res;

                        if ((authToken == null || authToken === '') && this.global.isUserLoggedIn()) {
                            // console.log('Token NULL or empty.');
                        } else if (!authToken) {
                            const headersNoAuth = new HttpHeaders({
                                'Content-Type': 'application/json; charset=utf-8'
                            });
                            const authReq = req.clone({ headers: headersNoAuth });
                            return next.handle(authReq).pipe(
                                map((event: HttpEvent<any>) => {
                                    // Request Good.
                                    return event;
                                }),
                                catchError((error: HttpErrorResponse) => {
                                    // Request Bad.
                                    this.httpError(error, authToken);
                                    return throwError(error);
                                })
                            );
                        } else {
                            const headers = new HttpHeaders({
                                Authorization: 'Bearer ' + authToken,
                                'Content-Type': 'application/json; charset=utf-8'
                            });
                            const authReq = req.clone({ headers });
                            return next.handle(authReq).pipe(
                                map((event: HttpEvent<any>) => {
                                    // Request Good.
                                    return event;
                                }),
                                catchError((error: HttpErrorResponse) => {
                                    // Request Bad.
                                    this.httpError(error, authToken);
                                    return throwError(error);
                                })
                            );
                        }
                    }
                )
            )



    }
    private httpError(error: any, authToken: any) {
        // // console.log('HTTP Error.', error, authToken);
        if ((error.status !== undefined && error.status === 400) &&
            (error.url !== undefined && error.url === environment.url + '/authenticate/')) {
            // console.log('Username or password was incorrect');
        } else if (error.status === 0 && error.statusText === 'Unknown Error') {
            // console.log('App data sync didnt complete');
        } else {
            if (error.status === 401 || error.status === 403) {
                this.global.retries++;
                if (this.global.retries >= 2) {
                    this.authService.logout(LogoutType.LoggedOut);
                    this.global.retries = 0;
                } else {
                    // console.log('401 Error');
                }
            }
        }
    }
}
