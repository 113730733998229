import { Component, EventEmitter, Input, Output, ViewEncapsulation, ElementRef, ViewChild, OnInit } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { AlertController } from '@ionic/angular';
import { RestProvider } from 'src/app/providers/rest/rest';
import { DataProvider } from 'src/app/providers/data/data';


export class IconInputSettings {
  placeholder = '';
  type = 'text';
  icon = '';
  styles = '';
  enabled = true;
  readonly = false;
  verify: boolean = null;
  loading = false;
  value: any = '';
  action = null;
  options: any = [];
  selectFailAdd = true;
  selectFirst = true;
  pattern = null;
  autocomplete?: any;
  title?;
  inputPattern?;
}

@Component({
  selector: 'icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class IconInputComponent implements OnInit {

  @ViewChild('inputArea', { static: false }) inputArea;
  @ViewChild('inputSelectSearch', { static: false }) selectSearch: IonicSelectableComponent;

  @Input() settings: IconInputSettings = new IconInputSettings();

  @Input() set placeholder(value: string) {
    this.settings.placeholder = value;
  }
  @Input() set type(value: string) {
    this.settings.type = value;
  }
  @Input() set icon(value: string) {
    this.settings.icon = value;
  }
  @Input() set autocomplete(value: string) {
    this.settings.autocomplete = value;
  }
  @Input() set styles(value: string) {
    this.settings.styles = value;
  }

  @Output() action = new EventEmitter();

  @Input() set enabled(value: boolean) {
    this.settings.enabled = value;
  }
  @Input() set readonly(value: boolean) {
    this.settings.readonly = value;
  }
  @Input() set pattern(value: string) {
    this.settings.pattern = value;
  }
  @Input() set title(value: string) {
    this.settings.title = value;
  }
  @Input() set inputPattern(value: string) {
    this.settings.inputPattern = value;
  }
  @Input() set selectFirst(value: boolean) {
    this.settings.selectFirst = value;
  }
  @Input() set selectFailAdd(value: boolean) {
    this.settings.selectFailAdd = value;
  }
  @Input() set verify(value: boolean) {
    this.settings.verify = value;
  }
  @Input() set loading(value: boolean) {
    this.settings.loading = value;
  }
  @Input() set options(value: any) {
    this.settings.options = value;
  }
  @Input() customCSS = '';


  @Input() set value(value: any) {
    this.settings.value = value;
  }
  @Output() valueChange = new EventEmitter();

  @Output() manualAdd = new EventEmitter();

  date = new Date();
  @Input() min = this.date.getFullYear() - 50;
  @Input() max = this.date.getFullYear() + 50;
  alertCtrl = new AlertController();
  selectOptions: any = {
    header: this.settings.placeholder,
    cssClass: this.customCSS
  };

  @Input() prefix: string;

  constructor(
    private rest: RestProvider,
    private alert: AlertController,
    private dataProvider: DataProvider) {
  }

  ngOnInit() {
    if (this.settings.type === 'date') {
      this.settings.icon = 'calendar';
      if (this.settings.value === 'today') {
        this.settings.value = new Date().toISOString();
      }
    } else if (this.settings.type === 'select' && this.settings.selectFirst) {
      if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
        this.settings.value = this.settings.options[0].value;
      }
    } else if (this.settings.type === 'search-select' && this.settings.selectFirst) {
      if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
        this.settings.value = this.settings.options[0];
      }
    } else if (this.settings.type === 'search-select-driver' && this.settings.selectFirst) {
      if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
        this.settings.value = this.settings.options[0];
      }
    }

    setTimeout(() => {
      this.inputChange();
      if (this.inputArea && this.inputArea.nativeElement) {
        this.inputArea.nativeElement.addEventListener('keyup', (event: KeyboardEvent) => {
          if (event.which === 13) { // If the input is active and 'Enter' has been pressed.
            this.action.emit();
          }
        });
      }
    }, 0);
  }

  ionViewDidEnter() {
  }

  inputChange() {
    if (this.settings.inputPattern && !this.inputArea.nativeElement.checkValidity()) {
      this.inputArea.nativeElement.reportValidity();
      this.valueChange.emit('');
      return;
    }

    if (this.settings.type === 'date' && this.settings.value) {
      this.valueChange.emit(this.settings.value.split('T')[0]);
    } else if (this.settings.type === 'search-select') {
      this.valueChange.emit(this.settings.value);
    } else if (this.settings.type === 'search-select-driver') {
      this.valueChange.emit(this.settings.value)
    } else {
      this.valueChange.emit(this.settings.value);
    }

    if (this.settings.pattern && this.settings.value) {
      setTimeout(() => {
        const regex = new RegExp(this.settings.pattern);
        const match = (regex.exec(this.settings.value) || [''])[0];
        this.settings.value = match;
        this.valueChange.emit(this.settings.value);
      });
    }


  }

  select_SelectableAction(event) {
    // Fix for Ionic Selectable search footer blocking select input.
    if (event.isSelected && this.selectSearch.searchText.length > 1) {
      this.settings.value = event.item;
      this.selectSearch.confirm();
      this.selectSearch.close();
    }
  }
  select_SelectableActionDriver(event) {
    // Fix for Ionic Selectable search footer blocking select input.
    if (event.isSelected && this.selectSearch.searchText.length > 1) {
      this.settings.value = event.item;
      this.selectSearch.confirm();
      this.selectSearch.close();
    }
  }

  click_Root() {
    if (this.settings.type === 'button' || this.settings.type === 'submit') {
      this.action.emit();
      this.inputArea.nativeElement.blur();
    } else if (this.settings.type === 'date') {
      this.inputArea.open();
    } else if (this.settings.type === 'select') {
      this.selectOptions.cssClass = this.customCSS;
      this.selectOptions.header = this.settings.placeholder;
      this.inputArea.open();
    } else if (this.settings.type === 'search-select') {
      // this.selectOptions.header = this.settings.placeholder;
      if (!this.selectSearch.isOpened) {
        this.selectSearch.open();
      }
    } else if (this.settings.type === 'search-select-driver') {
      if (!this.selectSearch.isOpened) {
        this.selectSearch.open();
      }
    } else {
      this.inputArea.nativeElement.focus();
    }
  }

  click_SelectableAddDriver(first_name: any, surname: any) {
    if (first_name.settings.value == '' || surname.settings.value == '') {
      alert('You must enter a first name and a surname');
      return false;
    }
    const foundDriver = this.selectSearch.items.find((x: any) => x.label.toLowerCase() === (first_name.settings.value.toLowerCase() + ' ' + surname.settings.value.toLowerCase()));
    if (foundDriver) {
      alert('There is a driver that already exists');
      return false;
    } else {
      this.alertCtrl = new AlertController();
      this.alertCtrl.create({
        header: 'Add new Driver',
        message: 'Are you sure you want to add ' + first_name.settings.value + ' ' + surname.settings.value + '?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              return false;
            }
          },
          {
            text: 'Ok',
            handler: () => {
              const newItem = {
                label: first_name.settings.value + ' ' + surname.settings.value,
                value: { id: -1, first_name: first_name.settings.value, surname: surname.settings.value, customer: 22, creation_source: 2 }
              };

              this.manualAdd.emit(newItem.value);

              this.selectSearch.addItem(newItem).then(() => {
                this.selectSearch.close();
                // console.log(this.settings.value)
                this.settings.value = newItem;
                // console.log(this.settings.value)
                setTimeout(() => {
                  this.inputChange();

                  // const foundObject = this.selectSearch.items.find((x: any) => x.value === -1);
                  // if (foundObject) {
                  //   this.selectSearch.deleteItem(foundObject);
                  // }
                }, 1);
              });
            }
          }
        ],
        backdropDismiss: false
      }).then(alertCtrl => alertCtrl.present());
    }
  }


  click_SelectableAdd(valToTrim: string) {
    // Remove any white space or spaces in registrations
    let val = valToTrim.replace(/\s/g, "");
    val = val.toUpperCase();

    const foundReg = this.selectSearch.items.find((x: any) => x.label.toLowerCase() === val.toLowerCase());
    if (foundReg) {
      alert('There is a vehicle that already exists in the list');
      return false;
    } else {
      this.alertCtrl = new AlertController();
      this.alertCtrl.create({
        header: 'Add new Registration',
        message: 'Are you sure you want to add ' + val + '?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              return false;
            }
          },
          {
            text: 'Ok',
            handler: () => {


              this.manuallyAddVehicle(val);

              // this.manualAdd.emit(newItem.value);

              // this.selectSearch.addItem(newItem).then(() => {
              //   this.selectSearch.close();
              //   this.settings.value = newItem;
              //   setTimeout(() => {
              //     this.inputChange();

              //     // const foundObject = this.selectSearch.items.find((x: any) => x.value === -1);
              //     // if (foundObject) {
              //     //   this.selectSearch.deleteItem(foundObject);
              //     // }
              //   }, 1);
              // });
            }
          }
        ],
        backdropDismiss: false
      }).then(alertCtrl => alertCtrl.present());
    }
  }

  public focus() {
    // console.log('Focus Please');
    setTimeout(() => {
      this.inputArea.nativeElement.focus();
    }, 50);
  }

  private async manuallyAddVehicle(reg) {
    // create the vehicle on datalive
    const cust = await this.dataProvider.getUserProfile();
    const newItem = {
      label: reg,
      value: { id: -1, registration: reg }
    };
    let newVehicle = {
      external_id: null,
      registration: reg,
      vehicle_type: null,
      creation_source: 2,
      customer: cust.customer
    };
    // const createdVehicle: any = await this.rest.createVehicle(newVehicle);
    this.rest.createVehicle(newVehicle).then(createdVehicle => {

      newVehicle.external_id = createdVehicle['id'];
      newVehicle = { ...newVehicle, ...createdVehicle };
      this.manualAdd.emit(newVehicle);
      newItem.value.id = newVehicle.external_id;
      this.selectSearch.addItem(newItem).then(() => {
        this.selectSearch.close();
        this.settings.value = newItem;
        setTimeout(() => {
          this.inputChange();
        }, 1);
      });


    }, err => {
      if (err.status && err.status == 400) {
        const dataBody = err.error;
        let errorMessage = '';

        for (const [key, value] of Object.entries(dataBody)) {
          console.log(`${key}: ${value}`);
          errorMessage += `${key}: ${value[0]}`
        }
        this.alert = new AlertController();
        this.alert.create({
          header: 'An issue with this registration',
          message: errorMessage,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                return false;
              }
            }
          ],
          backdropDismiss: false
        }).then(alertCtrl => alertCtrl.present());

      }
    });
  }


}
