import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalVariable } from '../global/global';
import { Defect, DefectDetails, DefectCategories, PaginatedResult, IDefectNote, AddedSignature, IDriverQuestionsList, IDepotConfig, IDepot, IDepotConfigResponse } from '../models/user-cust-models';
var RestProvider = /** @class */ (function () {
    function RestProvider(http, global) {
        this.http = http;
        this.global = global;
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.jwt = '';
        this.$isLoading = new BehaviorSubject(false);
    }
    /*
    * Global is loading obserable
    */
    RestProvider.prototype.setIsLoading = function (loadingValue) {
        this.$isLoading.next(loadingValue);
    };
    RestProvider.prototype.getIsLoading = function () {
        return this.$isLoading.asObservable();
    };
    RestProvider.prototype.Login = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.post(environment.url + '/api/authenticate/', JSON.stringify(data), { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.getUserInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var myHeaders, requestOptions, authUrl, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        myHeaders = new Headers();
                        myHeaders.append("Authorization", "Bearer " + this.jwt);
                        requestOptions = {
                            method: 'GET',
                            headers: myHeaders
                        };
                        authUrl = environment.authUrl;
                        return [4 /*yield*/, fetch(authUrl + "userinfo", requestOptions)];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, data.json()];
                    case 2: 
                    // const data = await fetch(`${environment.urlFMT}/fmt/user/get_current_user_with_image/`, requestOptions);
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RestProvider.prototype.GetCurrentUser = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.urlFMT + '/fmt/user/get_current_user_with_image/')
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DepotLocations = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.urlFMT + '/fmt/vehicle_groups/v2/', { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DepotRegistrations = function (depotID) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.urlFMT + '/fmt/paginated_vehicles_v2/?pageSize=75&page=1&ordering=registration&search=&vgid=' + depotID, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.GetDefects = function (depots, status, repairFilter, nameFilter, regFilter, vehTypeFilter, defectNumber, fullDefectNumber) {
        var _this = this;
        this.setIsLoading(true);
        var requestDate = Date.now();
        return new Promise(function (resolve, reject) {
            _this.http.get(
            // API
            environment.url + "/defect/?vehicle__isnull=False" +
                (
                // - Defect Status
                "" + (status && status.length > 0 ? '&status=' + status : '')) +
                (
                // - Driver name
                "" + ((nameFilter) ? '&driver_signature_object__printed_name__icontains=' + nameFilter : '')) +
                (
                // - Vehicle Reg
                "" + ((regFilter) ? '&server_vehicle_registration_when_created__icontains=' + regFilter : '')) +
                (
                // - Defect Assigned Repairer
                "" + ((repairFilter && repairFilter !== 'none') ? '&repairer__icontains=' + repairFilter : '')) +
                (
                // - Vehicle Depot
                "" + ((depots && depots !== []) ? '&vehicle_group__id=' + depots.join(',') : '')) +
                (
                // - Vehicle Type & Date Filter
                "" + (vehTypeFilter === true ? '&ordering=-vehicle__vehicle_type_name,-server_created_date' : '')) +
                (
                // - Repairer user defect number
                "" + (defectNumber ? '&defect_number__contains=' + defectNumber : '')) +
                (
                // - Full Defect number for filtering
                "" + (fullDefectNumber ? '&full_defect_number=' + fullDefectNumber : ''))).subscribe(function (res) {
                resolve(tslib_1.__assign({}, res, { timestamp: requestDate }));
                _this.setIsLoading(false);
            }, function (err) {
                reject(err);
                _this.setIsLoading(false);
            });
        });
    };
    RestProvider.prototype.GetNextDefectsPage = function (next) {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            _this.http.get(next).subscribe(function (res) {
                resolve(res);
                _this.setIsLoading(false);
            }, function (err) {
                reject(err);
                _this.setIsLoading(false);
            });
        });
    };
    RestProvider.prototype.GetDefectsAllStatus = function (status, repairFilter, depotFilter, nameFilter, regFilter, vehTypeFilter) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.url + ("/defect/?vehicle__isnull=False&status=" + status) +
                ("" + ((nameFilter) ? '&driver_signature_object__printed_name__icontains=' + nameFilter : '')) +
                ("" + ((regFilter) ? '&server_vehicle_registration_when_created__icontains=' + regFilter : '')) +
                ("" + ((repairFilter && repairFilter !== 'none') ? '&repairer__icontains=' + repairFilter : '')) +
                ("" + ((depotFilter && depotFilter !== 'none') ? '&vehicle_group__id=' + depotFilter : '')) +
                ("" + (vehTypeFilter === true ? '&ordering=-vehicle__vehicle_type_name,-server_created_date' : ''))).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectGet = function (uuid) {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.url + ("/defect/" + uuid + "/"))
                .subscribe(function (res) {
                _this.setIsLoading(false);
                resolve(res);
            }, function (err) {
                _this.setIsLoading(false);
                reject(err);
            });
        });
    };
    RestProvider.prototype.DriverList = function (pageNum) {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.urlFMT + ("/drivers_list/?ordering=first_name&page=" + pageNum)).subscribe(function (res) {
                _this.setIsLoading(false);
                resolve(res);
            }, function (err) {
                _this.setIsLoading(false);
                reject(err);
            });
        });
    };
    RestProvider.prototype.VehicleList = function (pageNum, vgid) {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.urlFMT +
                ("/fmt/simple_vehicle_list/?pageSize=75&ordering=registration&search=&vgid=" + vgid + "&page=" + pageNum)).subscribe(function (res) {
                _this.setIsLoading(false);
                resolve(res);
            }, function (err) {
                _this.setIsLoading(false);
                reject(err);
            });
        });
    };
    RestProvider.prototype.GetVehiclesWithoutDepots = function () {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.urlFMT +
                "/fmt/simple_vehicle_list/?restrict_vg_null=true&creation_source=2").subscribe(function (res) {
                _this.setIsLoading(false);
                resolve(res.results);
            }, function (err) {
                _this.setIsLoading(false);
                reject(err);
            });
        });
    };
    /**
     * Search vehicle simple list
     * @param searchTerm
     * @returns IPaginatedSimpleVehicleResponse
     */
    RestProvider.prototype.getSimpleVehicleList = function (searchTerm) {
        // All the magic happens in these two line:
        var _paginationParams = { pageSize: 50, page: 1, orderingDirection: '-', ordering: 'registration', search: searchTerm, 'allow_vg_null': true, 'customer_vehicles_only': true };
        var _httpParams = JSON.parse(JSON.stringify(_paginationParams));
        var params = new HttpParams({
            fromObject: _httpParams,
        });
        return this.http.get(environment.urlFMT + '/fmt/simple_vehicle_list/', { params: params });
    };
    /**
     * Search vehicle simple list
     * @param searchTerm
     * @returns IPaginatedSimpleVehicleResponse
     */
    RestProvider.prototype.getDriverList = function (searchTerm) {
        // All the magic happens in these two line:
        var _paginationParams = { pageSize: 30, page: 1, orderingDirection: '-', ordering: 'first_name', search: searchTerm };
        var _httpParams = JSON.parse(JSON.stringify(_paginationParams));
        var params = new HttpParams({
            fromObject: _httpParams,
        });
        // return this.http.get<IPaginatedSimpleVehicleResponse>( environment.urlFMT +'/fmt/simple_vehicle_list/', { params: params });
        return this.http.get(environment.urlFMT + "/drivers_list/", { params: params });
    };
    RestProvider.prototype.VehicleListGroup = function (vgid, pageNum, vehicleList) {
        var _this = this;
        if (pageNum === void 0) { pageNum = 1; }
        if (vehicleList === void 0) { vehicleList = []; }
        return new Promise(function (res, reject) {
            _this.VehicleList(pageNum, vgid).then(function (result) {
                vehicleList = vehicleList.concat(result.results);
                console.log('GetVehiclesWithoutDepots - page: ', pageNum, 'VGID: ', vgid);
                if (result.next) {
                    _this.VehicleListGroup(vgid, pageNum + 1, vehicleList).then(function (nextResult) { return res(nextResult); });
                }
                else {
                    res(vehicleList);
                }
            }), function (err) {
                reject(err);
            };
        });
    };
    RestProvider.prototype.DriverListAsync = function () {
        var _this = this;
        return new Promise(function (res, reject) {
            _this.DriverList(1).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var pagesNeededToFetch, maxRequests, currentRequests, pagesFetched, isLoadingRequests, combinedResult, vehicleBatch, error_1;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            pagesNeededToFetch = Math.ceil(result.count / result.results.length);
                            maxRequests = 15;
                            currentRequests = [];
                            currentRequests.push(result);
                            pagesFetched = 1;
                            isLoadingRequests = true;
                            combinedResult = [];
                            _a.label = 1;
                        case 1:
                            if (!isLoadingRequests) return [3 /*break*/, 6];
                            if (!(pagesFetched + currentRequests.length <= pagesNeededToFetch && currentRequests.length < maxRequests)) return [3 /*break*/, 2];
                            currentRequests.push(this.DriverList(pagesFetched + currentRequests.length));
                            return [3 /*break*/, 5];
                        case 2:
                            _a.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, Promise.all(currentRequests)];
                        case 3:
                            vehicleBatch = _a.sent();
                            pagesFetched += vehicleBatch.length;
                            currentRequests = [];
                            vehicleBatch.forEach(function (x) {
                                combinedResult = combinedResult.concat(x.results);
                            });
                            if (pagesNeededToFetch) {
                                console.log("[API] \uD83D\uDD03 Driver Fetched " + (pagesFetched - 1) + "/" + pagesNeededToFetch + " pages");
                            }
                            if (pagesFetched >= pagesNeededToFetch || !pagesNeededToFetch) {
                                isLoadingRequests = false;
                                res(combinedResult);
                            }
                            return [3 /*break*/, 5];
                        case 4:
                            error_1 = _a.sent();
                            // Catch a promise.all(). In a Promise.all(), Even if one promise fails it will break out of completing them all
                            console.log(error_1); // Console out the error
                            console.log("[API ERROR] Driver List | There are " + result.count + " drivers in total, pagesNeededToFetch: " + pagesNeededToFetch + " | pagesFetched: " + pagesFetched + " | currentRequests: " + currentRequests.length + " | current page number: " + (pagesFetched + currentRequests.length + 1));
                            isLoadingRequests = false;
                            // reject(combinedResult); // still return results
                            res(combinedResult); // still return results
                            return [3 /*break*/, 5];
                        case 5: return [3 /*break*/, 1];
                        case 6: return [2 /*return*/];
                    }
                });
            }); }, function (err) {
                debugger;
                console.log("[API] Error - DriverListAsync");
                reject(err);
            });
        });
    };
    RestProvider.prototype.rejectTimeout = function (reason, delay) {
        return new Promise(function (r, reject) { return setTimeout(function () { return reject(reason); }, delay); });
    };
    RestProvider.prototype.VehicleListGroupAsync = function (vgid) {
        var _this = this;
        return new Promise(function (res, reject) {
            _this.VehicleList(1, vgid).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var pagesNeededToFetch, maxRequests, currentRequests, pagesFetched, isLoadingRequests, combinedResult, vehicleBatch, error_2;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            pagesNeededToFetch = Math.ceil(result.count / result.results.length);
                            maxRequests = 15;
                            currentRequests = [];
                            pagesFetched = 1;
                            isLoadingRequests = true;
                            combinedResult = result.results;
                            _a.label = 1;
                        case 1:
                            if (!isLoadingRequests) return [3 /*break*/, 6];
                            console.log("[API] \uD83D\uDE97 VGID: " + vgid + " | " + result.count + " vehicles, pagesNeededToFetch: " + pagesNeededToFetch + " | pagesFetched: " + pagesFetched + " | currentRequests: " + currentRequests.length + " | current page number: " + (pagesFetched + currentRequests.length + 1));
                            if (!(pagesFetched + currentRequests.length + 1 <= pagesNeededToFetch && currentRequests.length < maxRequests)) return [3 /*break*/, 2];
                            currentRequests.push(this.VehicleList(pagesFetched + currentRequests.length + 1, vgid));
                            return [3 /*break*/, 5];
                        case 2:
                            _a.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, Promise.all(currentRequests)];
                        case 3:
                            vehicleBatch = _a.sent();
                            console.log("[AWAIT  FINISHED] vehicleBatch: ", vehicleBatch);
                            pagesFetched += vehicleBatch.length;
                            currentRequests = [];
                            vehicleBatch.forEach(function (x) {
                                combinedResult = combinedResult.concat(x.results);
                            });
                            if (pagesNeededToFetch) {
                                console.log("[API] \uD83D\uDD03 Fetched VGID: " + vgid + ": " + (pagesFetched - 1) + "/" + pagesNeededToFetch + " pages");
                            }
                            if (pagesFetched >= pagesNeededToFetch || !pagesNeededToFetch) {
                                isLoadingRequests = false;
                                res(combinedResult);
                            }
                            return [3 /*break*/, 5];
                        case 4:
                            error_2 = _a.sent();
                            // Catch a promise.all 1 second. in a Promise.all(), Even if one promise fails it will break out of completing them all
                            console.log(error_2); // Console out the error
                            console.log("[API ERROR] VGID: " + vgid + " | " + result.count + " vehicles, pagesNeededToFetch: " + pagesNeededToFetch + " | pagesFetched: " + pagesFetched + " | currentRequests: " + currentRequests.length + " | current page number: " + (pagesFetched + currentRequests.length + 1));
                            isLoadingRequests = false;
                            res(combinedResult);
                            return [3 /*break*/, 5];
                        case 5: return [3 /*break*/, 1];
                        case 6: return [2 /*return*/];
                    }
                });
            }); }, function (err) {
                debugger;
                // isLoadingRequests = false;
                console.log("[API] Error - VehicleListGroupAsync");
                reject(err);
            });
        });
    };
    RestProvider.prototype.VehicleListAllAsync = function () {
        var _this = this;
        return new Promise(function (res, reject) {
            var usersDepots = _this.global.userDepots;
            var cachedVehicles;
            var cachedVehicleTime = null;
            if (cachedVehicles) {
                var syncTimeout = 10;
                var timeDiff = Date.now() - cachedVehicleTime;
                var minutesApart = Math.round(((timeDiff % 86400000) % 3600000) / 60000);
                console.log("[API] \u23F0 Last vehicle sync " + minutesApart + " minute(s) ago");
                if (minutesApart < syncTimeout) {
                    console.log("[API] \uD83C\uDF10 We synced under " + syncTimeout + " minute(s) ago - serving cached vehicles list");
                    res(cachedVehicles);
                    return;
                }
                else {
                    console.log("[API] \u274C Vehicle cache expired " + (syncTimeout - minutesApart) + " minute(s) ago. Refreshing...");
                }
            }
            _this.DepotLocations().then(function (locations) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var vehicles, _i, locations_1, location_1, vehicleResult, error_3, vehiclesND;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            vehicles = [];
                            this.global.userDepots = locations;
                            _i = 0, locations_1 = locations;
                            _a.label = 1;
                        case 1:
                            if (!(_i < locations_1.length)) return [3 /*break*/, 6];
                            location_1 = locations_1[_i];
                            _a.label = 2;
                        case 2:
                            _a.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, this.VehicleListGroupAsync(location_1.id)];
                        case 3:
                            vehicleResult = _a.sent();
                            vehicles = vehicles.concat(vehicleResult);
                            // Set vehicles per depot
                            console.log("[API] \uD83C\uDFC1 Depot got (" + location_1.name + " " + location_1.id + "). Total Vehicles: " + vehicles.length);
                            return [3 /*break*/, 5];
                        case 4:
                            error_3 = _a.sent();
                            console.log("[API] \u274C VehicleListGroupAsync await sync error");
                            reject(vehicles);
                            return [3 /*break*/, 5];
                        case 5:
                            _i++;
                            return [3 /*break*/, 1];
                        case 6: return [4 /*yield*/, this.GetVehiclesWithoutDepots()];
                        case 7:
                            vehiclesND = _a.sent();
                            vehicles = vehicles.concat(vehiclesND);
                            console.log("[API] \uD83C\uDFC1 Done. Total Vehicles: " + vehicles.length);
                            // localStorage.setItem('registrations_no-depots', JSON.stringify({
                            //   time: Date.now(),
                            //   vehicles
                            // }));
                            res(vehicles);
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    RestProvider.prototype.DefectCreate = function (defect) {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.post(environment.url + '/defect/', defect, { headers: headers })
                .subscribe(function (res) {
                _this.setIsLoading(false);
                resolve(res);
            }, function (err) {
                _this.setIsLoading(false);
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectInit = function (defect) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // We dont want to send an empty array of question_responses to the API on create
            if (defect.question_responses && defect.question_responses.length === 0) {
                delete defect.question_responses;
            }
            var defectStripped = tslib_1.__assign({}, defect);
            delete defectStripped.defect_details;
            defectStripped = _this.global.StripNulls(defectStripped);
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.patch(environment.url + ("/defect/" + defect.uuid + "/update_and_create_defect_num/"), defectStripped, { headers: headers })
                .subscribe(function (res) {
                _this.DefectDetailsUpdate(defect.defect_details, defect).then(function (result) {
                    resolve(res);
                }).catch(function (error) {
                    reject(error);
                });
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectUpdate = function (defect) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // We dont want to send an empty array of question_responses to the API on update
            if (defect.question_responses && defect.question_responses.length === 0) {
                delete defect.question_responses;
            }
            var defectStripped = tslib_1.__assign({}, defect);
            delete defectStripped.defect_details;
            defectStripped = _this.global.StripNulls(defectStripped);
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.patch(environment.url + ("/defect/" + defect.uuid + "/"), defectStripped, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectCancel = function (defect) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.patch(environment.url + ("/defect/" + defect.uuid + "/cancel/"), {}, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectDetailsUpdate = function (defectDetails, defect) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var promiseArray = [];
            var count = 0;
            defectDetails.forEach(function (element) {
                if (defect) {
                    element.defect = defect.uuid;
                    element.status = defect.status;
                }
                if (element.index == null) {
                    element.index = count;
                }
                if (element['id'] != null) {
                    promiseArray.push(_this.DefectDetailPatch(element));
                }
                else {
                    element.new_signoff_action_required = true;
                    promiseArray.push(_this.DefectDetailCreate(element));
                }
                count++;
            });
            Promise.all(promiseArray).then(function (values) {
                resolve();
            }).catch(function (error) {
                reject(error);
            });
        });
    };
    RestProvider.prototype.DefectDetailPatch = function (defectDetails) {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.patch(environment.url + ("/defect_detail/" + defectDetails.uuid + "/"), defectDetails, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
                _this.setIsLoading(false);
            }, function (err) {
                reject(err);
                _this.setIsLoading(false);
            });
        });
    };
    RestProvider.prototype.DefectDetailCreate = function (defectDetails) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.post(environment.url + "/defect_detail/", defectDetails, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectEmail = function (uuid, email) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.post(environment.url + ("/defect/" + uuid + "/email_report/"), { email_to: email }, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectNotePost = function (note) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.post(environment.url + "/defect_action/", note, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectNotes = function (uuid) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.url + ("/defect/" + uuid + "/actions/"), { headers: headers })
                .subscribe(function (res) {
                // console.log('Notes;',res);
                resolve(res['results']);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectPDF = function (uuid) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.url + ("/defect/" + uuid + "/generate_pdf/"), { headers: headers })
                .subscribe(function (res) {
                // console.log('PDF', res);
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.GetRepairers = function (vehicleGroup) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.urlFMT + ("/vehicles_group_optimized/" + vehicleGroup + "/"), { headers: headers })
                .subscribe(function (res) {
                if (res && res.vehicle_group_contacts) {
                    resolve(res.vehicle_group_contacts);
                }
                else {
                    reject();
                }
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.GetCustomerRepairers = function (customerID) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.urlFMT + ("/customer_repairers/" + customerID + "/"), { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.GetRepairerTechs = function (repairerID) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.urlFMT + ("/" + repairerID + "/technicians/"), { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    // Should be able to remove this.
    // check to make sure everything works before doing so.
    // UpdateDefectAndDefectDetails(defect: Defect) {
    //   // Copy the defect to a new object.
    //   let tempDefect = {...defect};
    //   // Now copy the defect details to send.
    //   let defectDetailsToSend:DefectDetails[] = [...tempDefect.defect_details];
    //   // Renove the defect details from the main defect and remove all nulls for patching.
    //   tempDefect.defect_details = null;
    //   let defectToSend:Defect = this.global.StripNulls(tempDefect);
    //   // Wrap everything in a promise that we can return.
    //   return new Promise((resolve, reject) => {
    //     // First we are going to send off the defect report.
    //     this.DefectPatch(defectToSend).then((defectResult) => {
    //       // If that comes back good then we will send the details.
    //       // console.log('Defect Patched. Now for details...');
    //       let promiseArray = [];
    //       // For each detail we will create a promise and add it to an array.
    //       defectDetailsToSend.forEach(element => {
    //         // Set the UUID of the Defect Details object.
    //         // the api will reject if this is not done.
    //         // Do these need their own UUID's generated or use the defect report one?
    //         // UUID now created per details row in the front end.    element.uuid = defect.uuid;
    //         element.status = defect.status;
    //         element.defect = defect.uuid;
    //         if(element['id'] != null) {
    //           promiseArray.push(this.DefectDetailPatch(element));
    //         } else {
    //           promiseArray.push(this.DefectDetailCreate(element));
    //         }
    //       });
    //       // When all promises have resolved then we move on.
    //       Promise.all(promiseArray).then((values) => {
    //         // Resolve as everything was good!
    //         // console.log('Defect Details Updated!')
    //         resolve();
    //       }).catch((error) => {
    //         // Something went wrong.
    //         // console.log('Failed to patch all defect details.');
    //         reject(error);
    //       })
    //     }).catch((error) => {
    //       // console.log('Failed to patch defect report.');
    //       reject(error);
    //     })
    //   });
    // }
    // DefectPatch(defect:Defect) {
    //   return new Promise((resolve, reject) => {
    //     const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    //     this.http.patch(environment.url + `/defect/${defect.uuid}/`, defect, {headers})
    //       .subscribe(res => {
    //         resolve(res);
    //       }, (err) => {
    //         reject(err);
    //       });
    //   });
    // }
    RestProvider.prototype.GetTemplateDefectCatagories = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.url + "/template_defect_categories/", { headers: headers })
                .subscribe(function (res) {
                var result = res;
                resolve(result.results);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.DefectAddSignature = function (defectUUID, addedSiganture) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.post(environment.url + ("/defect/" + defectUUID + "/add_signature/"), addedSiganture, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.RepairerAuthID = function (techPin, repairerID) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.post(environment.urlFMT + "/validate_tech_pin/", { pin: techPin, repairer: repairerID }, { headers: headers })
                .subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.RepairerDetail = function (id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.urlFMT + ("/repairers/" + id + "/"), { headers: headers })
                .subscribe(function (res) {
                resolve(res.results);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.RepairerStatus = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            _this.http.get(environment.url + "/defect_repair_status_definitions/", { headers: headers })
                .subscribe(function (res) {
                resolve(res.results);
            }, function (err) {
                reject(err);
            });
        });
    };
    RestProvider.prototype.registrationLookup = function (vehicle) {
        return this.http.get(environment.urlFMT + "/vehicles/", { headers: this.headers, params: { registration: vehicle.registration } }).toPromise();
    };
    RestProvider.prototype.createVehicle = function (vehicle) {
        return this.http.post(environment.urlFMT + "/vehicles/", vehicle, { headers: this.headers }).toPromise();
    };
    RestProvider.prototype.createDriver = function (driver) {
        return this.http.post(environment.urlFMT + "/drivers_list/", driver, { headers: this.headers }).toPromise();
        //return this.http.post(`http://localhost:8080/api/drivers_list/`, driver, { headers: this.headers }).toPromise();
    };
    // Driver Question API's
    // getDriverQuestions(custId: number): Promise<any> {
    //   return this.http.get(
    //     `${environment.urlFMT}customer_questions_list/${custId}/`,
    //     { headers: this.headers }
    //   ).toPromise();
    // }
    RestProvider.prototype.getDriverQuestions = function (custId, depotId) {
        var _this = this;
        this.setIsLoading(true);
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.url + "/customer_questions_list/" + custId + "/" + depotId + "/").subscribe(function (res) {
                _this.setIsLoading(false);
                resolve(res);
            }, function (err) {
                _this.setIsLoading(false);
                reject(err);
            });
        });
    };
    RestProvider.prototype.getDepotConfig = function (external_id) {
        var _this = this;
        var url = environment.url + "/depot_config/";
        if (Array.isArray(external_id)) {
            url += "?depot_ids=" + external_id.join(',');
        }
        else {
            url += external_id + "/";
        }
        return new Promise(function (resolve) {
            _this.http.get(url).subscribe(function (res) { return resolve(res.results); }, function (_err) { return resolve([]); });
        });
    };
    RestProvider.prototype.getVehicleDepots = function (vehicle_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.http.get(environment.urlFMT + "/vehicle/" + vehicle_id + "/vehicle_groups/")
                .subscribe(function (res) { return resolve(res); }, function (_err) { return resolve([]); });
        });
    };
    return RestProvider;
}());
export { RestProvider };
