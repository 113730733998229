import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent implements OnInit {

  @Input() set options(value: DropdownOptions) {
    this._options = value;
  }

  open:boolean = false;

  _options:DropdownOptions = {
    header: "Dropdown",
    items: [
      {
        label: "Item One",
        action: () => { console.log("Hello One!") }
      },
      {
        label: "Item Two",
        action: () => { console.log("Hello Two!") }
      },
      {
        label: "Item Three",
        action: () => { console.log("Hello Three!") },
        icon: 'ios-arrow-round-forward'
      }
    ],
    seperateLast: true
  };

  constructor() { }

  ngOnInit() {}

  click_ToggleMenu() {
    this.open = !this.open;
  }

}

export interface DropdownOptions {
  header:string,
  items:DropdownItem[],
  seperateLast?:boolean
}

export interface DropdownItem {
    label:string,
    action:Function,
    icon?:string
}
