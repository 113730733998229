import { Injectable,  Input } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertServiceService {
 header: any;
 message: string;
 action: any;
 cancelAction: any;

  private alertCtrl = new AlertController();
  public newState: boolean;
  constructor() {
  }

  async show(input) {
    let result = false;
    const alert = await this.alertCtrl.create({
      header: input.heading,
      message: input.messageText,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            result = false;
          }
        },
        {
          text: 'Ok',
          role: 'true',
          handler: () => {
            result = true;
          }
        }
      ],
      backdropDismiss: false
    });
    await alert.present();
    let clicked = await alert.onDidDismiss();
    return this.newState = result as boolean;
  }
}
