import { Injectable } from '@angular/core';
import { CustomerModel, Defect, DefectStatus, IVehicle } from '../models/user-cust-models';
import { ToastController, AlertController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RestProvider } from '../rest/rest';
import { isArray } from 'util';

@Injectable()
export class GlobalVariable {

    public user: any;
    public customer: CustomerModel;
    public customerLogo: string;
    public retries = 0;

    public defectsInLastSearch;
    public repairStatuses = null;
    public addedNotes = 0;

    public userDepots: any[] = null;
    public registrations: any[] = null;
    public drivers: any[] = null;

    public requireReauthentication = false;
    public defectReadonly = false;

    public majorCat: any;

    constructor(
        private toastController: ToastController,
        private alertController: AlertController,
    ) {
    }

    public isUserLoggedIn(): boolean {
        if (this.user != null && this.user.user != null) {
            if (this.user.user.id == null) {
                return false;
            }
            return true;
        }
        return false;
    }

    public async toast(message: string, duration: number = 3000, options?, cssClass?: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: duration,
            buttons: options,
            cssClass: 'notification-toast ' + cssClass
        });
        toast.present();
    }

    public async alert(header: string, message: string, buttons?: AlertButton[]) {
        const alert = await this.alertController.create({
            header: header,
            message: message,
            buttons: buttons
        });
        alert.present();
    }

    public DefectDetails(statusID: number) {
        const key = Object.values(DefectStatus).find((x: any) => x.id == statusID);
        return key;
    }

    public StripNulls(obj: any) {
        let strippedObject = {};

        Object.keys(obj).forEach(key => {
            // we need to account for the fact that a property could be an array
            // otherwise we will create an object where the key is the array index
            // and the value is each indexes value {0: {}, 1: {}}
            if (obj && obj.constructor === Array && obj.length > 0) {
                strippedObject = obj.map(item => this.StripNulls(item));
            } else if (obj[key] && typeof obj[key] === 'object') {
                // If the property is an object then we will strip that too recursively.
                strippedObject[key] = this.StripNulls(obj[key]);
            } else if (obj[key] !== null) {
                strippedObject[key] = obj[key];
            }
        });

        return strippedObject;
    }

    public AtRepairer(defectStatus: number) {
        return defectStatus === DefectStatus.At_Repairer.id ||
            defectStatus === DefectStatus.Signed_Off_Roadworthy.id;
    }


    public getDefectStatusActionTrailText(defect: Defect): string {
        let actiontext: string = '';
        switch (defect.status) {
            case DefectStatus.Verified_Signed_Off.id:
                actiontext = `Manager signoff with manager editing driver defect`;
                break;
            case DefectStatus.At_Repairer.id:
                actiontext = `Defect assigned to a repairer ${defect.repairer.name}`;
                break;
            case DefectStatus.Signed_Off_Roadworthy.id:
                actiontext = `Defect set to Roadworthy from ${defect.repairer.name}`;
                break;
            case DefectStatus.Repaired.id:
                actiontext = `Defect Repaired`;
                break;

            default:
                break;
        }


        return actiontext;
    }
}
