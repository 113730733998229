/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./user-dropdown.component";
var styles_UserDropdownComponent = [i0.styles];
var RenderType_UserDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDropdownComponent, data: {} });
export { RenderType_UserDropdownComponent as RenderType_UserDropdownComponent };
function View_UserDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-icon", [], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(1, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "c-user-drop--body--item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.action() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDropdownComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_UserDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "c-user-drop"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "open": 0, "seperateLast": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "c-user-drop--head"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click_ToggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "c-user-drop--head--text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ion-icon", [["class", "c-user-drop--head--profile"], ["name", "contact"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(8, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "c-user-drop--head--icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ion-icon", [["name", "ios-arrow-down"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(11, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "c-user-drop--body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDropdownComponent_1)), i1.ɵdid(14, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "c-user-drop"; var currVal_1 = _ck(_v, 3, 0, _co.open, _co._options.seperateLast); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = "contact"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "ios-arrow-down"; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co._options.items; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co._options.header; _ck(_v, 6, 0, currVal_2); }); }
export function View_UserDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-dropdown", [], null, null, null, View_UserDropdownComponent_0, RenderType_UserDropdownComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserDropdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDropdownComponentNgFactory = i1.ɵccf("app-user-dropdown", i5.UserDropdownComponent, View_UserDropdownComponent_Host_0, { options: "options" }, {}, []);
export { UserDropdownComponentNgFactory as UserDropdownComponentNgFactory };
