import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
var PermissionsProvider = /** @class */ (function () {
    function PermissionsProvider(http) {
        this.http = http;
        this.modulePermissions = [];
    }
    PermissionsProvider.prototype.LoadRole = function (roles) {
        var _this = this;
        this.role = roles instanceof Array ?
            roles.filter(function (r) { return r.ExternalAppReference === 'DEFECT'; })
                // make sure we map the rolename check to set the old values
                .map(function (r) { return _this.RoleNameCheck(r); }) : this.RoleNameCheck(roles);
        // if its not an array then return the singluar object
        this.roleName = roles instanceof Array ? this.role[0].name : this.role.name;
    };
    /**
     * we need this to check against the inconsistency of names
     * for instance the IsRepairer needs to be is_repairer, this is
     * temporary for backwards compatibility
     *
     * so we basically add an is_repairer if the IsRepairer is true
     */
    PermissionsProvider.prototype.RoleNameCheck = function (role) {
        return tslib_1.__assign({}, role, { name: role.PermissionName, is_admin: role.IsGlobalAdmin, is_manager: role.IsManager, is_repairer: role.IsRepairer, is_readonly: role.IsReadonly });
    };
    PermissionsProvider.prototype.LoadModulePermissions = function (permissions) {
        this.modulePermissions = permissions;
    };
    PermissionsProvider.prototype.hasRolePermission = function (roleKey) {
        if (this.role instanceof Array) {
            for (var _i = 0, _a = this.role; _i < _a.length; _i++) {
                var role = _a[_i];
                if (role[roleKey] === true) {
                    return true;
                }
            }
            return false;
        }
        else {
            return this.role[roleKey] === true;
        }
    };
    PermissionsProvider.prototype.isntRolePermission = function (roleKey) {
        if (this.role instanceof Array) {
            for (var _i = 0, _a = this.role; _i < _a.length; _i++) {
                var role = _a[_i];
                if (!role[roleKey]) {
                    return true;
                }
            }
            return false;
        }
        else {
            return !!this.role[roleKey];
        }
    };
    PermissionsProvider.prototype.hasModulePermission = function (permission) {
        var _perm = this.modulePermissions.find(function (perm) { return perm.name === permission; });
        return _perm != null ? true : false;
    };
    return PermissionsProvider;
}());
export { PermissionsProvider };
