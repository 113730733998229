import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/app/components/components.module';
import { IonicModule } from '@ionic/angular';
import { DashboardPage } from './dashboard.page';

import { AuthGuard } from 'src/app/guards/auth.guard';
import { PipesModule } from 'src/app/providers/pipes/pipes.module';
import { HomePage } from './home/home.page';

const dashboardRoutes: Routes = [
  // { path: 'dashboard', redirectTo: 'dashboard/homescreen', pathMatch: 'full' },
  {
    path: '',
    component: DashboardPage,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
        {
            path: '',
            component: HomePage
        },
       { path: 'defect', loadChildren: './defect-add/defect-add.module#DefectAddPageModule' }
        // {
        //   path: 'defect/add/:uuid',
        //   component: DefectAddPage
        // },
        // {
        //   path: 'defect/view/:uuid',
        //   component: DefectAddPage
        // },
        // {
        //   path: 'defect/update/:uuid',
        //   component: DefectAddPage
        // },
        // {
        //   path: 'defect/notes/:uuid',
        //   component: DefectNotesPage
        // }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    // DashboardRoutingModule
    RouterModule.forChild(dashboardRoutes),
    PipesModule,
  ],
  declarations: [DashboardPage, HomePage]
})
export class DashboardPageModule {}
