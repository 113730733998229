<ion-content no-bounce has-bouncing="false" forceOverscroll="false" [scrollY]="false">

  <div class="c-home-wrap">
    <section class="onboard-wrap onboard-wrap__edge onboard-wrap__dark"
      *ngIf="permissionService.hasRolePermission('is_manager') || permissionService.hasRolePermission('is_admin') || permissionService.hasRolePermission('is_repairer')">
      <icon-input class="onboard-wrap--item toggle-filter-btn" (action)="toggleFilter()"
        value="{{ toogleFilterContainer ? 'Close' : 'Open' }} filter options" type="button" styles="small white"
        icon="{{ toogleFilterContainer ? 'ios-arrow-up' : 'ios-arrow-down'}}"></icon-input>
      <!-- <icon-input class="onboard-wrap--item" (action)="click_AddDefect()" placeholder="Search" icon="search" styles="white"></icon-input> -->
      <icon-input class="onboard-wrap--item"
        *ngIf="!permissionService.hasRolePermission('is_repairer') && !permissionService.hasRolePermission('is_readonly')"
        (action)="click_AddDefect()" value="Add New Defect" type="button" icon="add" [enabled]="!addingDefect"
        styles="dark"></icon-input>

    </section>
    <section class="onboard-wrap__dark filter-toggle-container"
      [ngClass]="{'filter-toggle-container--show': toogleFilterContainer}">
      <div class="onboard-wrap onboard-wrap__edge">
        <div class="onboard-filter-wrap">
          <h2>Depot/Dept filter</h2>
          <icon-input placeholder="Depot/Dept filter" type="search-select" [selectFailAdd]="false"
            [(value)]="depotFilter" (valueChange)="select_RepairFilterChange($event)" styles="small white"
            [enabled]="depots" [loading]="!depots" [options]="depots" [selectFirst]="false"></icon-input>
        </div>
        <div class="onboard-filter-wrap">
          <h2>Vehicle Reg/Number filter</h2>
          <icon-input type="input" placeholder="Vehicle Reg/Number filter" styles="small white" [(value)]="regFilter"
            (valueChange)="select_RepairFilterChange($event)"></icon-input>
        </div>
        <!-- Defect Number for non-Repairer -->
        <div class="onboard-filter-wrap" *ngIf="!permissionService.hasRolePermission('is_repairer')">
          <h2>Defect Number (XX-XXXXXX)</h2>
          <icon-input type="input" prefix="#" placeholder="00-00000" styles="small white"
            (valueChange)="select_NumberFilterChange($event)" name="defectNumberFullFilter"
            inputPattern="[\w]{2,}-[\w]{2,}" title="XX-XXXXXX">
          </icon-input>
        </div>
        <!-- Defect Number for Repairer -->
        <div class="onboard-filter-wrap" [style.width]="permissionService.hasRolePermission('is_repairer') ? '22%' : ''"
          *ngIf="permissionService.hasRolePermission('is_repairer')">
          <h2>Defect Number</h2>
          <icon-input type="input" prefix="#XX-" placeholder="Defect Number..." styles="small white"
            [(value)]="defectNumberFilter" (valueChange)="select_RepairFilterChange($event)" name="defectNumFilter">
          </icon-input>
        </div>
        <div class="onboard-filter-wrap" *ngIf="!permissionService.hasRolePermission('is_repairer')">
          <h2>Driver filter</h2>
          <icon-input type="input" placeholder="Driver Name..." autocomplete="false" styles="small white"
            [(value)]="driverFilter" (valueChange)="select_RepairFilterChange($event)" name="drvFilter"></icon-input>
        </div>
        <div class="onboard-filter-wrap" *ngIf="!permissionService.hasRolePermission('is_repairer')">
          <h2>Repairer/Workshop filter</h2>
          <icon-input placeholder="Repairer/Workshop filter" type="search-select" [selectFailAdd]="false"
            [(value)]="repairFilter" (valueChange)="select_RepairFilterChange($event)" styles="small white"
            [loading]="isLoadingRepairers" [enabled]="repairers && !isLoadingRepairers" [loading]="!repairers"
            [options]="repairers" [selectFirst]="false"></icon-input>
        </div>
        <div class="onboard-filter-wrap filter-by-vehicle-type-item"
          *ngIf="!permissionService.hasRolePermission('is_repairer')">
          <h2>Filter by Vehicle Type </h2>
          <icon-input [ngClass]="{'filter-by-vehicle-type-btn--selected': filterByVehicleTypeSelected }"
            class="filter-by-vehicle-type-btn" (action)="filterByVehicleType()" value="Vehicle Type & Date"
            type="button" styles="small white"
            icon="{{filterByVehicleTypeSelected ? 'md-radio-button-on' : 'md-radio-button-off'}}"></icon-input>
        </div>
      </div>
    </section>

    <div class="segment-container " style="flex-wrap: wrap; padding: 10px;"
      *ngIf="permissionService.hasRolePermission('is_manager') || permissionService.hasRolePermission('is_admin') || permissionService.hasRolePermission('is_repairer')">

      <!-- Defect Types -->
      <!-- <div class="segment-container__title"><ion-icon name="funnel"></ion-icon> Filter</div> -->
      <div class="home-filter-scroll">
        <div class="segment-container--col" *ngFor="let searchItem of searchOptions">
          <ion-chip [outline]="!searchItem.selected" [color]="searchItem.defect.color"
            (click)="toggleDefect(searchItem.defect)" [ngClass]="{'selected': searchItem.selected}">
            <ion-icon *ngIf="searchItem.defect.icon" [name]="searchItem.defect.icon"></ion-icon>
            <ion-label>{{searchItem.defect.name}}</ion-label>
            <ion-icon *ngIf="searchItem.selected" name="close-circle"></ion-icon>
          </ion-chip>
        </div>
      </div>
    </div>

    <section class="onboard-wrap onboard-wrap__vertical onboard-results">
      <!-- <ion-list style="width: 100%"> -->
      <ion-content>
        <ion-refresher (ionRefresh)="getDefects()">
          <ion-refresher-content refreshingSpinner="dots"></ion-refresher-content>
        </ion-refresher>

        <ion-item *ngFor="let defect of defects" (click)="click_More(defect)">
          <ion-label class="c-driver-item">
            <div class="c-driver-item--side">
              <div class="c-driver-image"
                [ngStyle]="{'border-color': 'var(--ion-color-' + global.DefectDetails(defect.status).color + ')'}">
                <ion-icon [name]="global.DefectDetails(defect.status).icon"
                  [color]="global.DefectDetails(defect.status).color"></ion-icon>
              </div>
              <div class="c-driver-details">
                <span class="c-driver-details--name">
                  <p *ngIf="defect.vehicle">{{defect.vehicle.registration}} <span
                      *ngIf="defect.vehicle.vehicle_type_name">({{defect.vehicle.vehicle_type_name}})</span>,</p>
                  <p>{{defect.driver_printed_name != '' ? defect.driver_printed_name : 'No driver name'}}</p>
                  <p class="c-driver-details--number" *ngIf="defect.defect_number">
                    #{{defect.location_ref_number}}-{{defect.defect_number}}</p>
                  <span class="c-driver-details--alert" *ngIf="defect.vor === true" title="VOR">!</span>
                </span>
                <span class="c-driver-details--license">
                  Created : {{defect.server_created_date | date:'dd/MM/yyyy HH:mm'}}
                  {{defect.server_user_notes_count ? " - " + defect.server_user_notes_count + ' note(s)' : ''}}
                  <span class="c-driver-details--repairer"
                    *ngIf="defect.status === defectStatusObject.At_Repairer.id">{{defect.repairer?.name}}</span>
                  <br>
                  Updated : {{defect.server_last_updated | date:'dd/MM/yyyy HH:mm'}}
                </span>
              </div>
            </div>
            <!-- More Buttons + Actions -->
            <div class="c-driver-item--side c-driver-item--side__end">
              <div class="c-driver-actions">
                <span class="c-driver-details--categories" *ngIf="defect.defect_details">
                  <p *ngFor="let details of defect.defect_details">
                    {{details.category}}
                  </p>
                </span>
              </div>
              <ion-icon class="c-driver-actions--more" name="md-more"></ion-icon>
            </div>
          </ion-label>

        </ion-item>

        <div class="c-home-search-message" *ngIf="defects?.length == 0">
          <p>{{ searchMessage }}</p>
        </div>

        <ion-infinite-scroll style="width: 100%" (ionInfinite)="loadData()">
          <ion-infinite-scroll-content loadingSpinner="dots" loadingText="Loading Defects...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>


      </ion-content>
      <!-- </ion-list> -->

    </section>

    <div class="require-login" *ngIf="requireLogin">
      <div class="require-login--info">

      </div>
      <div class="require-login--input driver">

          <h3 class="require-login--title">Confirm to unlock</h3>
          <div>
              <p>I confirm that i have returned the device back to the manager after creating a defect.</p>
          </div>
          <icon-input class="require-login--input--submit" [loading]="loadingReauth" (action)="Reauthenticate()"
              styles="dark" value="I Confirm" type="button"
              icon="done-all">
          </icon-input>
      </div>
    </div>
    <!-- require reauth -->
    <div class="require-login" *ngIf="requireLogin">
      <div class="require-login--info">
      </div>
      <div class="require-login--input driver">
        <h3 class="require-login--title">Confirm to unlock</h3>

          <div class="require-login-consent">
              <ion-item>
                  <ion-checkbox slot="start" (ionChange)="confirmAuth(authConsentDriver, 'driver')"
                      value="{{authConsentDriver}}" [(ngModel)]="authConsentDriver"></ion-checkbox>
                  <ion-label><strong>Driver: </strong> I confirm that I have returned the device back to the manager after creating/repairing a defect.</ion-label>
              </ion-item>
              <ion-item>
                <ion-checkbox slot="start" (ionChange)="confirmAuth(authConsentManager, 'manager')"
                    [(ngModel)]="authConsentManager"></ion-checkbox>
                <ion-label><strong>Manager: </strong> I confirm that the details have been entered fully and correctly onto the defect note.</ion-label>
            </ion-item>       
         
          </div>


          <icon-input class="require-login--input--submit" [loading]="loadingReauth" (action)="Reauthenticate()"
            styles="dark" value="I Confirm" type="button" [enabled]="authConsentDriver && authConsentManager"
            icon="done-all">
      </icon-input>
      </div>
    </div>

  </div>

</ion-content>