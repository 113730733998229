import * as tslib_1 from "tslib";
import { CustomerModel, Defect, DefectStatus } from '../models/user-cust-models';
import { ToastController, AlertController } from '@ionic/angular';
var GlobalVariable = /** @class */ (function () {
    function GlobalVariable(toastController, alertController) {
        this.toastController = toastController;
        this.alertController = alertController;
        this.retries = 0;
        this.repairStatuses = null;
        this.addedNotes = 0;
        this.userDepots = null;
        this.registrations = null;
        this.drivers = null;
        this.requireReauthentication = false;
        this.defectReadonly = false;
    }
    GlobalVariable.prototype.isUserLoggedIn = function () {
        if (this.user != null && this.user.user != null) {
            if (this.user.user.id == null) {
                return false;
            }
            return true;
        }
        return false;
    };
    GlobalVariable.prototype.toast = function (message, duration, options, cssClass) {
        if (duration === void 0) { duration = 3000; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: message,
                            duration: duration,
                            buttons: options,
                            cssClass: 'notification-toast ' + cssClass
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    GlobalVariable.prototype.alert = function (header, message, buttons) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: header,
                            message: message,
                            buttons: buttons
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    GlobalVariable.prototype.DefectDetails = function (statusID) {
        var key = Object.values(DefectStatus).find(function (x) { return x.id == statusID; });
        return key;
    };
    GlobalVariable.prototype.StripNulls = function (obj) {
        var _this = this;
        var strippedObject = {};
        Object.keys(obj).forEach(function (key) {
            // we need to account for the fact that a property could be an array
            // otherwise we will create an object where the key is the array index
            // and the value is each indexes value {0: {}, 1: {}}
            if (obj && obj.constructor === Array && obj.length > 0) {
                strippedObject = obj.map(function (item) { return _this.StripNulls(item); });
            }
            else if (obj[key] && typeof obj[key] === 'object') {
                // If the property is an object then we will strip that too recursively.
                strippedObject[key] = _this.StripNulls(obj[key]);
            }
            else if (obj[key] !== null) {
                strippedObject[key] = obj[key];
            }
        });
        return strippedObject;
    };
    GlobalVariable.prototype.AtRepairer = function (defectStatus) {
        return defectStatus === DefectStatus.At_Repairer.id ||
            defectStatus === DefectStatus.Signed_Off_Roadworthy.id;
    };
    GlobalVariable.prototype.getDefectStatusActionTrailText = function (defect) {
        var actiontext = '';
        switch (defect.status) {
            case DefectStatus.Verified_Signed_Off.id:
                actiontext = "Manager signoff with manager editing driver defect";
                break;
            case DefectStatus.At_Repairer.id:
                actiontext = "Defect assigned to a repairer " + defect.repairer.name;
                break;
            case DefectStatus.Signed_Off_Roadworthy.id:
                actiontext = "Defect set to Roadworthy from " + defect.repairer.name;
                break;
            case DefectStatus.Repaired.id:
                actiontext = "Defect Repaired";
                break;
            default:
                break;
        }
        return actiontext;
    };
    return GlobalVariable;
}());
export { GlobalVariable };
