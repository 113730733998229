import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DefectNumberPipe } from './defect-number/defect-number.pipe';

@NgModule({
  declarations: [
    DefectNumberPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DefectNumberPipe
  ]
})
export class PipesModule { }
