export const environment = {
  production: true,
  url: 'https://api-dot-datalive-defect-report-staging.appspot.com',
  urlFMT: 'https://dashboard.datalive-staging.com/api',
  ssoAuthUrl: 'https://defect.datalive-staging.com/oauth2/',
  authUrl: 'https://auth.datalive-staging.com/',
  appReference: 'DEFECT',
  dataliveUrl: 'https://dashboard.datalive-staging.com',
  developmentAccessToken: null
};
