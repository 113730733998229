import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { DropdownOptions } from 'src/app/components/generic/user-dropdown/user-dropdown.component';
import { BrandOptions } from 'src/app/components/custom/profile-header/profile-header.component';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { GlobalVariable } from 'src/app/providers/global/global';
import { PermissionsProvider } from 'src/app/providers/permissions/permissions';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {

  brandOptions: BrandOptions = {
    name: 'Brand Name',
    logoUrl: 'http://www.placehold.it/300x300'
  };
  subtitle = 'Defect Dashboard';

  menuOptions: DropdownOptions = {
    header: 'Staff Title',
    seperateLast: true,
    items: [{
      label: 'Loading...',
      action: () => {
        console.log('Edit Preferences...');
      }
    },
    {
      label: 'Logout',
      icon: 'ios-arrow-round-forward',
      action: () => { this.logout(); }
    }]
  };
  defects: any = []; // TODO - Needs type/interfacing

  constructor(
    public navCtrl: NavController,
    public authService: AuthService,
    private global: GlobalVariable,
    private permissionService: PermissionsProvider
  ) { }

  ngOnInit() {
    if (!this.global.customer || !this.authService.isAuthenticated) {
      this.navCtrl.navigateRoot('/');
    } else {
      const userProfile = this.authService.getUserProfile();
      this.brandOptions.name = this.global.customer.name;
      this.brandOptions.logoUrl = 'data:image/png;base64,' + this.global.customer.logo;
      this.menuOptions.header = userProfile.extra_vars.firstName + ' ' + userProfile.extra_vars.lastName;
      this.menuOptions.items[0].label = 'Role: ' + userProfile.extra_vars.permissions[0].PermissionName;



      if (this.permissionService.hasRolePermission('is_readonly')) {
        this.menuOptions.items.splice(1, 0, {
          action: () => { },
          label: 'View Only'
        })
      }
    }
  }


  logout() {
    console.log('Logging Out...');
    this.authService.logout();
    setTimeout(() => {
      this.navCtrl.navigateBack('/');
    }, 250);
  }

}
