import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Platform } from '@ionic/angular';
import { reject } from 'q';

@Injectable()
export class MediaProvider {

    id: string = 'fileinput-' + Math.random().toString(36).substr(0, 9);
    static activeStreams = [];

    constructor(
        private platform: Platform,
        private camera: Camera
    ) {

    }

    isMediaStreamSupported() {
        return navigator.mediaDevices != undefined;
    }

    getPhotos(multiple?: boolean): Promise<FileList> {
        return this.getBrowserPhotos(multiple);
    }

    urlToData64(url: string): any {
        return new Promise<any>((res, rej) => {
            fetch(url).then(response => response.blob()).then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => res(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }).catch((error) => {
            reject(error);
        });
    }

    fileToData64(file: File) {
        return new Promise<string>((res, rej) => {
            const reader = new FileReader();
            reader.onload = (data: any) => {
                res(data.target.result);
            };
            reader.readAsDataURL(file);
        });
    }

    data64ToFile(data64: string, filename: string = "Photo") {
        return new Promise<File>((res, rej) => {
            let blobBin = atob(data64.split(',')[1]);
            let array = [];
            for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }
            let blob = new Blob([new Uint8Array(array)], { type: 'image/png' });
            let file: File = new File([blob], filename, { type: 'image/png', lastModified: Date.now() });

            res(file);
        });
    }

    getPhotosDataURL(multiple?: boolean): Promise<string[]> {
        return new Promise<string[]>((res, rej) => {
            this.getPhotos(multiple).then(result => {
                let dataArray = [];
                const reader = new FileReader();
                reader.onload = (data: any) => {
                    dataArray.push(data.target.result);
                    if (dataArray.length == result.length) {
                        res(dataArray);
                    }
                };
                for (let i = 0; i < result.length; i++) {
                    reader.readAsDataURL(result[i]);
                }
            });
        });
    }

    getDevicePhoto(sourceType: number) {
        // Mobile so show camera
        const options: CameraOptions = {
            quality: 60,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            correctOrientation: true,
            sourceType: sourceType,
        }

        return new Promise<string>((res, rej) => {
            this.camera.getPicture(options).then((imageData) => {
                let base64Image = 'data:image/jpeg;base64,' + imageData;
                res(base64Image);
            }, (err) => {
                rej("Error getting photo.");
            });
        })
    }

    getBrowserPhotos(multiple?: boolean, types?: string) {
        return new Promise<FileList>((res, rej) => {
            let element = document.createElement("input");
            if (multiple) {
                element.setAttribute("multiple", "true");
            }
            element.type = "file";
            element.accept = types || 'image/*';
            document.body.appendChild(element);

            element.onchange = () => {
                res((<HTMLInputElement>element).files);
                element.remove();
            };

            element.click();
        })
    }

    canMediaStream() {
        return location.protocol == 'https';
    }

    getMediaStream(video: boolean = true, audio?: boolean, preferFront?) {
        return new Promise<MediaStream>((res, rej) => {
            let consts = {
                video: preferFront ? { facingMode: "user" } : { facingMode: "environment" },
                audio: audio
            };
            navigator.mediaDevices.getUserMedia(consts).then((stream) => {
                let mediaStream: MediaStream = stream;
                MediaProvider.activeStreams.push(mediaStream);
                res(mediaStream);
            }).catch((error) => {
                rej(error);
            });
        })
    }

    stopAllMediaStream() {
        MediaProvider.activeStreams.forEach(stream => {
            let tracks: MediaStreamTrack[] = stream.getTracks();
            tracks.forEach(track => { track.stop(); });
        });
        MediaProvider.activeStreams = [];
    }

    captureFrameFromStream(stream: MediaStream, resolution: { x: number, y: number }) {
        return new Promise<string>((res, rej) => {
            let element = document.createElement("video");
            element.style.visibility = "hidden";
            document.body.appendChild(element);
            element.srcObject = stream;
            element.play();
            setTimeout(() => {
                this.captureFrameFromVideo(element, resolution).then(data => res(data)).catch(error => rej(error));
            }, 100);
        });
    }

    captureFrameFromVideo(video: HTMLVideoElement, resolution?: { x: number, y: number }) {
        return new Promise<string>((res, rej) => {
            if (!resolution) {
                resolution = { x: video.videoWidth, y: video.videoHeight };
            }

            let canvas = document.createElement("canvas");
            canvas.style.visibility = "hidden";
            document.body.appendChild(canvas);
            let context = canvas.getContext('2d');
            context.canvas.width = resolution.x;
            context.canvas.height = resolution.y;
            context.drawImage(video, 0, 0, resolution.x, resolution.y);
            let data = canvas.toDataURL();
            res(data);
            canvas.remove();
        });
    }

    stopMediaStream(stream: MediaStream) {
        let tracks: MediaStreamTrack[] = stream.getTracks();
        tracks.forEach(track => {
            track.stop();
        });

        MediaProvider.activeStreams.forEach(stream => {
            let tracks: MediaStreamTrack[] = stream.getTracks();
            tracks.forEach(track => {
                track.stop();
            });
        });
    }

    generateRandomGradiant(width: number, height: number) {
        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');

        let gradiant = ctx.createLinearGradient(0, 0, canvas.width, 0);
        gradiant.addColorStop(0, this.generateRandomHex());
        gradiant.addColorStop(1, this.generateRandomHex());

        ctx.fillStyle = gradiant;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        return canvas.toDataURL();
    }

    generateRandomHex() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    /**
     * @description Convert a Web URL or File to an image object
     * @returns Image object with the URL as it's source
     */
    convertToImage(data: string | File) {
        return new Promise<HTMLImageElement>((res) => {
            const img = new Image();
            img.onload = () => {
                res(img);
            };
            img.src = URL.createObjectURL(data);
        })
    }

    /**
     * @description Resizes an image based off of given constraints
     * @param image 
     * @param options 
     * @returns  
     */
    resizeImage(image: HTMLImageElement, options: { mode: 'scale' | 'exact' | 'clamp', size: number | { w?: number, h?: number }, output: 'blob' | 'dataurl', type: string }) {
        return new Promise<any>((res) => {
            const canvas = document.createElement('canvas');
            let width = typeof options.size === 'number' ? options.size : options.size.w;
            let height = typeof options.size === 'number' ? options.size : options.size.h;

            if (options.mode === 'exact') {
                // Do nothing
            } else if (options.mode === 'scale') {
                width = image.width * width;
                height = image.height * height;
            } else if (options.mode === 'clamp') {
                if (width && height) {
                    // Do nothing
                } else if (width && !height) {
                    height = image.height * (width / image.width);
                } else if (!width && height) {
                    width = image.width * (height / image.height);
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            if (options.output === 'blob') {
                canvas.toBlob((e) => {
                    res(e)
                }, options.type);
            } else {
                res(canvas.toDataURL(options.type));
            }
        });
    }

    /**
     * @description Converts a photo to a file
     * @param url URL of the photo to convery
     * @param name Name of the file to be output
     * @returns  
     */
    photoToFile(url: string, name: string) {
        return new Promise<File>((res) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onload = async () => {
                const file = xhr.response;
                file.lastModifiedDate = new Date();
                file.name = name;
                res(file as File);
            };
            xhr.send();
        });
    }

    /**
     * @description Converts a data url into a file format.
     * @param dataurl 
     * @param filename 
     * @returns  
     */
    dataURLtoFile(dataurl: string, filename: string) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
}