import { NgModule } from '@angular/core';
import { SignaturePadComponent } from './signature-pad.component';

@NgModule({
  imports: [ ],
  declarations: [ SignaturePadComponent ],
  exports: [ SignaturePadComponent ],
})

export class SignaturePadModule { }
