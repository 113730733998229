<div class="c-user-drop" [ngClass]="{'open': open, 'seperateLast': _options.seperateLast}">
  <!-- Menu Header -->
  <div class="c-user-drop--head" (click)="click_ToggleMenu()">
    <span class="c-user-drop--head--text">{{_options.header}}</span>
    <ion-icon class="c-user-drop--head--profile" name="contact"></ion-icon>
    <span class="c-user-drop--head--icon"><ion-icon name="ios-arrow-down"></ion-icon></span>
  </div>
  <!-- Menu _options -->
  <div class="c-user-drop--body">
    <!-- Option Item -->
    <span class="c-user-drop--body--item" *ngFor="let item of _options.items" (click)="item.action()">
      {{item.label}}
      <ion-icon *ngIf="item.icon" [name]="item.icon"></ion-icon>
    </span>
  </div>
</div>